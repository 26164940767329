import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTwitter,
    faInstagram,
    faFacebookF,
} from "@fortawesome/free-brands-svg-icons";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import MenuItemFooter, {
    MenuItemFooterProps,
} from "../component/footer/menu-item-footer";
import { LogoClisha } from "../../Components/logo/logo";
import translator from "../../Components/Extra/Translation/Translate";

export default function Footer() {
    const { translate } = translator();

    const menuItems: MenuItemFooterProps[] = [
        {
            className: "text-lg",
            label: translate("FOOTER", "OFFERS"),
            items: [
                {
                    path: "/earns",
                    className: "hover:text-clisha-500",
                    label: translate("HeadLanding", "Earn"),
                },
                {
                    path: "/buys",
                    className: "hover:text-clisha-500",
                    label: translate("HeadLanding", "Buy"),
                },
                {
                    path: "/exchanges",
                    className: "hover:text-clisha-500",
                    label: translate("HeadLanding", "Exchange"),
                },
            ],
        },
        {
            className: "text-lg",
            label: translate("FOOTER", "LEARN_MORE"),
            items: [
                {
                    path: "/entreprises",
                    className: "hover:text-clisha-500",
                    label: translate("HeadLanding", "Companies"),
                },
                {
                    path: "/conseillers-financiers",
                    className: "hover:text-clisha-500",
                    label: translate("HeadLanding", "Financial_advisors"),
                },
                {
                    path: "/white-label",
                    className: "hover:text-clisha-500",
                    label: translate("HeadLanding", "White_label"),
                },
                {
                    path: "/affiliations",
                    className: "hover:text-clisha-500",
                    label: "Affiliation",
                },
            ],
        },
        {
            className: "text-lg",
            label: "Information",
            items: [
                {
                    path: "/legal-notice",
                    className: "hover:text-clisha-500",
                    label: translate("FOOTER", "LEGAL_NOTICE"),
                },
                {
                    path: "/condition",
                    className: "hover:text-clisha-500",
                    label: translate("FOOTER", "T&C"),
                },
                {
                    path: "/privacy-policy",
                    className: "hover:text-clisha-500",
                    label: translate("FOOTER", "PRIVACY_POLICY"),
                },
            ],
        },
    ];
    return (
        <footer className="bottom-0 w-full bg-clisha-600">
            <div className="flex justify-between md:items-start md:flex-row flex-col mx-8 lg:mx-32 gap-7 py-6 text-white">
                <div className="flex flex-col items-center space-y-3">
                    <LogoClisha className="w-[250px]" />
                    <p className="text-xs">©2024 All rights reserved</p>
                    <ul className="flex space-x-2">
                        <li className="rounded-md py-2 px-4 bg-clisha-500">
                            <Link to="#">
                                <FontAwesomeIcon
                                    icon={faFacebookF}
                                    className="text-[18px]"
                                />
                            </Link>
                        </li>
                        <li className="rounded-md py-2 px-3 bg-clisha-500">
                            <Link to="#">
                                <FontAwesomeIcon
                                    icon={faTwitter}
                                    className="text-[18px]"
                                />
                            </Link>
                        </li>
                        <li className="rounded-md py-2 px-3 bg-clisha-500">
                            <Link to="#">
                                <FontAwesomeIcon
                                    icon={faInstagram}
                                    className="text-[18px]"
                                />
                            </Link>
                        </li>
                        <li className="rounded-md py-2 px-3 bg-clisha-500">
                            <Link to="#">
                                <FontAwesomeIcon
                                    icon={faPaperPlane}
                                    className="text-[18px]"
                                />
                            </Link>
                        </li>
                    </ul>
                </div>
                {menuItems.map((item, index) => (
                    <div
                        key={`item-${index}`}
                        className="space-y-1 pt-4 text-left md:text-center"
                    >
                        <MenuItemFooter
                            className={item.className}
                            label={item.label}
                            items={item.items}
                        />
                    </div>
                ))}
            </div>
        </footer>
    );
}
