import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBars,
    faClose,
    faPenToSquare,
    faRightToBracket,
    faSortDesc,
} from "@fortawesome/free-solid-svg-icons";
import translator from "../../Components/Extra/Translation/Translate";
import { useAppDispatch } from "../../globalState/hook";
import useLanguage from "../../Services/Hooks/useLanguage";
import {
    ChangeAppLanguage,
    Language,
} from "../../globalState/reducers/Language.reducer";
import CustomButton from "../component/Bouton/CustomButton";
import MenuItem, { MenuItemProps } from "../component/nav/menu-item";
import { clsx } from "@mantine/core";
import { useLocation } from "react-router-dom";
import { LogoClisha } from "../../Components/logo/logo";
import useAuthentication from "../../Services/Authentication/useAuthentication";
import UtilsService from "../../Services/UtilsService";

interface Option {
    label: string;
    value: string;
    image: any;
}

const AuthButton = () => {
    const { translate } = translator();

    const { isAuthenticated } = useAuthentication();

    return (
        <ul className="flex justify-center items-center gap-2 text-base lg:mb-0">
            <li className="space-x-3 flex">
                {isAuthenticated && (
                    <CustomButton
                        href="/dashboard"
                        className="!border-clisha-500 md:text-sm hover:!bg-clisha-600  px-3 py-1 text-white text-xd rounded-xl bg-clisha-500 flex items-center"
                    >
                        {translate("GENERAL", "MY_ACCOUNT")}
                    </CustomButton>
                )}
                {!isAuthenticated && (
                    <>
                        <CustomButton
                            href="/register"
                            className="md:text-sm text-gray-800 px-3 py-1 hover:!bg-clisha-500 !border-transparent bg-white text-xd flex items-center"
                        >
                            {translate("SignIn", "Register")}
                            <FontAwesomeIcon
                                className="ml-1 md:text-base text-sm ms:ml-0"
                                icon={faPenToSquare}
                            />
                        </CustomButton>
                        <CustomButton
                            href="/login"
                            className="!border-clisha-500 md:text-sm hover:!bg-clisha-600  px-3 py-1 text-white text-xd rounded-xl bg-clisha-500 flex items-center"
                        >
                            {translate("SignIn", "Sign_In")}
                            <FontAwesomeIcon
                                className="ml-1 md:text-base text-sm ms:ml-0"
                                icon={faRightToBracket}
                            />
                        </CustomButton>{" "}
                    </>
                )}
            </li>
        </ul>
    );
};

const Header: React.FC = () => {
    const [sideNavWidthSide, setSideNavWidthSide] = useState("0px");
    const { translate } = translator();
    const { language } = useLanguage();
    const dispatch = useAppDispatch();
    const [icon, setIcon] = useState(faBars);

    const location = useLocation();

    const language_list: Array<Option> = [
        {
            label: `${translate("Head", "English")}`,
            value: Language.EN,
            image: UtilsService.getPulicImage("/images/language/en.webp"),
        },
        {
            label: `${translate("Head", "French")}`,
            value: Language.FR,
            image: UtilsService.getPulicImage("/images/language/fr.webp"),
        },
    ];

    const currentLang = language_list.find((i) => i.value === language);

    const openNavSide = () => {
        setSideNavWidthSide("100vw");
        setIcon(faClose);
    };

    const closeNavSide = useCallback(() => {
        setSideNavWidthSide("0px");
        setIcon(faBars);
    }, []);

    const menuItems: MenuItemProps[] = useMemo(() => {
        return [
            { path: "/", label: `${translate("HeadLanding", "Home")}` },
            {
                path: "#",
                label: translate("HeadLanding", "Services"),
                children: [
                    {
                        path: "/buys",
                        label: `${translate("HeadLanding", "Buy")}`,
                    },
                    {
                        path: "/exchanges",
                        label: `${translate("HeadLanding", "Exchange")}`,
                    },
                    {
                        path: "/earns",
                        label: `${translate("HeadLanding", "Earn")}`,
                    },
                ],
            },
            {
                path: "#",
                label: translate("HeadLanding", "Professionals"),
                children: [
                    {
                        path: "/entreprises",
                        label: `${translate("HeadLanding", "Companies")}`,
                    },
                    {
                        path: "/conseillers-financiers",
                        label: `${translate(
                            "HeadLanding",
                            "Financial_advisors",
                        )}`,
                    },
                    {
                        path: "/white-label",
                        label: `${translate("HeadLanding", "White_label")}`,
                    },
                ],
            },
            {
                path: "#",
                label: translate("HeadLanding", "Helps"),
                children: [
                    {
                        path: "/news",
                        label: `${translate("HeadLanding", "News")}`,
                    },
                    {
                        path: "/faq",
                        label: `${translate("HeadLanding", "Faq")}`,
                    },
                ],
            },
        ];
    }, [language]);

    useEffect(() => {
        closeNavSide();
    }, [closeNavSide, location.pathname]);

    return (
        <>
            <header className="fixed z-50 bg-clisha-600 w-full top-0 inset-x-0">
                <nav className="flex items-center justify-between mx-2 md:px-6 py-2 text-[#303131]">
                    <a className="w-[150px] md:w-[200px]" href="/">
                        <LogoClisha />
                    </a>
                    <div className="hidden text-white md:!flex items-center ml-auto ">
                        <ul className="flex justify-center items-center gap-2 lg:mb-0">
                            {menuItems.map((menu, index) => (
                                <MenuItem key={`menu-${index}`} {...menu} />
                            ))}
                        </ul>
                    </div>
                    <div className="hidden text-white lg:flex items-center ml-auto ">
                        <AuthButton />
                    </div>

                    <div className="flex space-x-2 items-center  !z-40">
                        <div className="relative group  text-white">
                            <button className="flex items-center cursor-pointer justify-between py-2 px-4 hover:text-clisha-500">
                                <img
                                    className="max-w-[25px]"
                                    src={currentLang?.image}
                                    alt={currentLang?.label}
                                />
                                <FontAwesomeIcon
                                    icon={faSortDesc}
                                    className={clsx("ml-2 text-sm", {
                                        "rotate-180 mt-2": false,
                                        "mb-2": !false,
                                    })}
                                />
                            </button>

                            <div className="absolute right-3 md:!pt-4 pt-1">
                                <ul className="hidden  group-hover:block  md:!w-[150px] w-[120px] top-[50px] z-10 bg-clisha-600 space-y-2 rounded-xl py-2 border border-clisha-500">
                                    {language_list.map((item: Option) => (
                                        <li
                                            key={item.value}
                                            className="cursor-pointer"
                                        >
                                            <button
                                                onClick={() =>
                                                    dispatch(
                                                        ChangeAppLanguage({
                                                            language:
                                                                item.value as Language,
                                                        }),
                                                    )
                                                }
                                                className="text-sm flex hover:text-[#DB9B1E] items-center w-full px-4"
                                            >
                                                <img
                                                    alt="User Dropdown"
                                                    className="rounded-full size-6 object-fill my-auto mr-2"
                                                    src={item.image}
                                                />{" "}
                                                <span className="md:!text-sm text-xs">
                                                    {item.label}
                                                </span>
                                            </button>
                                            <hr className="w-full border-white/[0.08] my-1" />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <button
                            type="button"
                            onClick={
                                sideNavWidthSide === "0px"
                                    ? openNavSide
                                    : closeNavSide
                            }
                            aria-controls="navbarSupportedContent"
                            aria-label="Toggle navigation"
                            className="lg:!hidden"
                        >
                            <FontAwesomeIcon
                                icon={icon}
                                className="text-xl text-clisha-500"
                            />
                        </button>
                    </div>
                </nav>

                {/* <!-- Sidebar Left navigation --> */}
                <aside>
                    <div
                        className="lg:hidden fixed z-30 top-[45px] left-0 h-full w-0 bg-clisha-600 overflow-hidden transition-all duration-500"
                        style={{
                            width: sideNavWidthSide,
                        }}
                    >
                        <ul className="flex flex-col items-start space-y-5 my-10 text-white ">
                            {menuItems.map((menu, index) => (
                                <Fragment key={`m-menu-${index}`}>
                                    <MenuItem {...menu} />
                                    <hr className="w-full border-white/[0.08] my-1" />
                                </Fragment>
                            ))}
                        </ul>
                        <AuthButton />
                    </div>
                </aside>
            </header>
        </>
    );
};

export default Header;
