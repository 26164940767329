import React from "react";
import FeatureCard from "../../component/FeatureCard";
import {
    faCreditCard,
    faTags,
    faWallet,
} from "@fortawesome/free-solid-svg-icons";
import translator from "../../../Components/Extra/Translation/Translate";

export default function BuySecondSection() {
    const { translate } = translator();

    return (
        <div>
            <section className="bg-[#F7F7F7]  px-8 py-32 space-y-8 lg:!px-32 ">
                <h1
                    data-aos="flip-right"
                    className="text-gray-800 md:font-bold md:text-5xl text-3xl text-center  "
                >
                    {translate("BUY", "PAYMENT_TITLE")}
                </h1>
                <div className="grid grid-cols-1 md:!grid-cols-3 gap-8 max-md:max-w-md mx-auto ">
                    <div data-aos="flip-up">
                        <FeatureCard
                            icon={faCreditCard}
                            title={translate("BUY", "CREDIT_CARD")}
                            description={translate(
                                "BUY",
                                "CREDIT_CARD_DESCRIPTION",
                            )}
                            classname="bg-clisha-904"
                        />
                    </div>
                    <div data-aos="flip-up">
                        <FeatureCard
                            icon={faTags}
                            title={translate("BUY", "DISCOUNT")}
                            description={translate(
                                "BUY",
                                "DISCOUNT_DESCRIPTION",
                            )}
                            classname="bg-clisha-904"
                        />
                    </div>
                    <div data-aos="flip-up">
                        <FeatureCard
                            icon={faWallet}
                            title="Kalypay"
                            description={translate(
                                "BUY",
                                "KALYPAY_DESCRIPTION",
                            )}
                            classname="bg-clisha-904"
                        />
                    </div>
                </div>
            </section>
        </div>
    );
}
