import React from "react";

import {
    faChartLine,
    faClock,
    faHandHoldingDollar,
} from "@fortawesome/free-solid-svg-icons";
import FeatureCard from "../../component/FeatureCard";
import translator from "./../../../Components/Extra/Translation/Translate";

export default function Section1() {
    const { translate } = translator();
    return (
        <div className="bg-[#F7F7F7] py-32">
            <section className="flex justify-center items-center mx-8 lg:!mx-32">
                <div className="min-w-screen-xl mx-auto w-full space-y-8">
                    <h2
                        data-aos="flip-right"
                        className="text-clisha-600 md:font-bold md:text-5xl text-4xl text-center "
                    >
                        {translate("EARN", "SECTION_2_TITLE")}
                    </h2>

                    <div className="grid grid-cols-1 md:!grid-cols-3 gap-8 max-md:max-w-md mx-auto">
                        <div data-aos="flip-up">
                            <FeatureCard
                                icon={faChartLine}
                                title={translate("EARN", "CARD_1_TITLE")}
                                description={translate("EARN", "CARD_1_TITLE")}
                            />
                        </div>
                        <div data-aos="flip-up">
                            <FeatureCard
                                icon={faHandHoldingDollar}
                                title={translate("EARN", "CARD_2_TITLE")}
                                description={translate("EARN", "CARD_2_TITLE")}
                            />
                        </div>
                        <div data-aos="flip-up">
                            <FeatureCard
                                icon={faClock}
                                title={translate("EARN", "CARD_3_TITLE")}
                                description={translate("EARN", "CARD_3_TITLE")}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
