import { Navigate, Outlet, useLocation } from "react-router-dom";

import useAuthentication from "../../Services/Authentication/useAuthentication";

interface IProps {
    children?: JSX.Element;
}

const AdminRouteProvider = ({ children }: IProps) => {
    const { isAuthenticated, isAdmin } = useAuthentication();
    const location = useLocation();

    if (isAuthenticated) {
        if (isAdmin) {
            return children ? children : <Outlet />;
        } else {
            return (
                <Navigate
                    to={"/"}
                    state={{ from: location.pathname }}
                    replace
                />
            );
        }
    } else {
        return (
            <Navigate
                to={"/login"}
                state={{ from: location.pathname }}
                replace
            />
        );
    }
};

export default AdminRouteProvider;
