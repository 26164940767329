import React from "react";
import Section1 from "../ui/WhiteLabel/section1";
import Section2 from "../ui/WhiteLabel/Section2";
import Section3 from "../ui/WhiteLabel/section3";
import Section4 from "../ui/WhiteLabel/section4";
import HelmetComponent from "../component/HelmetComponent";
import translator from "../../Components/Extra/Translation/Translate";

export default function WhiteLabel() {
    const { translate } = translator();
    return (
        <>
            <HelmetComponent
                title={translate("SEO", "White_label_title")}
                description={translate("SEO", "White_label_desc")}
                link="/white-label"
                keywords={translate("SEO", "White_label_keywords")}
                imageCard="/dist/image/clishanodes-cards-1024x1024.png"
                largeTwitterCard="/dist/image/clishanodes-cards-1024x1024.png"
                addPostfixTitle={true}
                noIndex={false}
            ></HelmetComponent>

            <Section1 />
            <Section2 />
            <Section3 />
            <Section4 />
        </>
    );
}
