import { UserCryptoWallet } from "./UserWallet";

export default class Transaction {
    id?: number;
    description: string;
    amount: number;
    status: string;
    date: string;
    type!: WalletHistoryType;
    user_wallet!: UserCryptoWallet;
    user?: any;
    fee?: number;
    target?: UserCryptoWallet;
    constructor(
        description: string,
        amount: number,
        status: string,
        date: string,
        user: any,
    ) {
        this.description = description;
        this.amount = amount;
        this.status = status;
        this.date = date;
        this.user = user;
    }
}

export interface OTransactionCount {
    deposit: number;
    all: number;
    withdraw: number;
    all_transactions: number;
}
/* eslint-disable no-unused-vars */
export enum WalletHistoryType {
    DEPOSIT = "deposit",
    WITHDRAW = "withdraw",
    WITHDRAW_MANUAL = "withdraw_manual",
    STAKING = "staking",
    STOP_STAKING = "stop_staking",
    UNSTAKING = "unstaking",
    TRANSFER_IN = "transferin",
    TRANSFERT_OUT = "transferout",
    COMMISSION = "commission",
    SWAPIN = "swapin",
    SWAPOUT = "swapout",
    DAILY_ROI = "daily_roi",
    DAILY_KNT = "daily_knt",
    ORDER_PACKBOOSTER = "order_packbooster",
    DEPOSIT_VISA = "deposit_visa",
    DEPOSIT_VIREMENT = "deposit_virement",
    DEPOSIT_KALYPAY = "deposit_kalypay",
    DEPOSIT_CRYPTO = "deposit_crypto",
    WITHDRAW_KALYPAY = "withdraw_kalypay",
    WITHDRAW_CRYPTO = "withdraw_crypto",
    RANK_BONUS = "rank_bonus",
    POOL_BONUS = "pool_bonus",
    COMMISSION_PURCHASE = "commission_purchase",
    COMMISSION_LOYALTY = "commission_loyalty",
    COMMISSION_SWAP = "commission_swap",
    COMMISSION_WITHDRAW = "commission_withdraw",
    COMMISSION_SERVICE_CHARGE = "commission_service_charge",
    PURCHASE_FIDELITY = "purchase_fidelity",
    CASHBACK_CLISHA = "cashback_klc",
    SOLDE_MIGRATION = "solde_migration",
    BUY_NODES = "buy_nodes",
}
/* eslint-disable no-unused-vars */
export interface ICSVTransaction {
    type: string;
    date: string;
    amount: number;
    crypto_in: string;
    crypto_out: string;
    fee: number;
    crypto_fee: string;
    swapping_plateform: string;
    description: string;
    label: string;
}

export enum WalletHistoryStatus {
    INITIALIZED = "initialized",
    PENDING = "pending",
    COMPLETED = "completed",
    CANCELLED = "cancelled",
}

export const OUT_TRANSACTIONS = [
    WalletHistoryType.BUY_NODES,
    WalletHistoryType.WITHDRAW,
    WalletHistoryType.WITHDRAW_CRYPTO,
    WalletHistoryType.WITHDRAW_KALYPAY,
    WalletHistoryType.TRANSFERT_OUT,
    WalletHistoryType.SWAPOUT,
    WalletHistoryType.ORDER_PACKBOOSTER,
    WalletHistoryType.PURCHASE_FIDELITY,
    WalletHistoryType.WITHDRAW_MANUAL,
    WalletHistoryType.STAKING,
];

export const NEUTRAL_TRANSACTIONS = [WalletHistoryType.STOP_STAKING];
