import React from "react";
import { useEffect, useState } from "react";
import alert_message from "../Services/Alert/AlertMessage";
import { Asset } from "../Models/Asset";
import { getAllAssets } from "../Services/Assets/asset.service";

export default function useAssets() {
    const [assets, setAssets] = useState<Asset[]>([]);

    useEffect(() => {
        getAllAssets()
            .then((data) => {
                setAssets(data);
            })
            .catch((err) => {
                alert_message.error(err);
            });
    }, []);

    return {
        assets,
    };
}
