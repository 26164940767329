export interface ITransaction {
    crypto_abbreviation: string;
    amount: number;
    token2FA: string;
}

/* eslint-disable */
export enum TransactionType {
    CREDIT = "CREDIT",
    DEBIT = "DEBIT",
}
/* eslint-enable */
