import React from "react";

import translator from "../../../Components/Extra/Translation/Translate";

interface QuestionAnswer {
    question: string;
    answer: string;
}

const generateQuestionsData = (
    keys: { question: string; answer: string }[],
): QuestionAnswer[] => {
    const { translate } = translator();
    return keys.map((key) => ({
        question: translate("FAQ", key.question),
        answer: translate("FAQ", key.answer),
    }));
};

export const questionsData1 = (): QuestionAnswer[] =>
    generateQuestionsData([
        { question: "SECTION_2_Q1", answer: "SECTION_2_R1" },
        { question: "SECTION_2_Q2", answer: "SECTION_2_R2" },
        { question: "SECTION_2_Q3", answer: "SECTION_2_R3" },
        { question: "SECTION_2_Q4", answer: "SECTION_2_R4" },
        { question: "SECTION_2_Q5", answer: "SECTION_2_R5" },
    ]);

export const questionsData2 = (): QuestionAnswer[] =>
    generateQuestionsData([
        { question: "SECTION_2_Q6", answer: "SECTION_2_R6" },
        { question: "SECTION_2_Q7", answer: "SECTION_2_R7" },
        { question: "SECTION_2_Q8", answer: "SECTION_2_R8" },
        { question: "SECTION_2_Q9", answer: "SECTION_2_R9" },
        { question: "SECTION_2_Q10", answer: "SECTION_2_R10" },
        { question: "SECTION_2_Q11", answer: "SECTION_2_R11" },
        { question: "SECTION_2_Q12", answer: "SECTION_2_R12" },
    ]);

export const questionsData3 = (): QuestionAnswer[] =>
    generateQuestionsData([
        { question: "SECTION_2_Q13", answer: "SECTION_2_R13" },
        { question: "SECTION_2_Q14", answer: "SECTION_2_R14" },
        { question: "SECTION_2_Q15", answer: "SECTION_2_R15" },
        { question: "SECTION_2_Q16", answer: "SECTION_2_R16" },
        { question: "SECTION_2_Q17", answer: "SECTION_2_R17" },
    ]);

export const questionsData4 = (): QuestionAnswer[] =>
    generateQuestionsData([
        { question: "SECTION_2_Q18", answer: "SECTION_2_R18" },
        { question: "SECTION_2_Q19", answer: "SECTION_2_R19" },
    ]);
