import React from "react";
import "./CustomButtonCss.css";
import { NavLink } from "react-router-dom";

interface CustomButtonProps {
    href: string;
    download?: string;
    className?: string;
    type?: "button" | "submit" | "reset";
    disabled?: boolean;
    children?: React.ReactNode;
    onClick?: (
        e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
    ) => void;
}

const CustomButton: React.FC<CustomButtonProps> = ({
    href,
    download,
    className,
    type,
    disabled,
    children,
    onClick,
}) => {
    const handleClick = (
        e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
    ) => {
        if (onClick) {
            onClick(e);
        }

        if (download) {
            e.preventDefault();
            const link = document.createElement("a");
            link.href = href;
            link.download = download;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const commonClasses = ` ${className} btn-hover1 inline-block overflow-hidden rounded-xl border-[1px] transition-all duration-300 ease-linear text-center `;

    if (type || disabled) {
        return (
            <button
                type={type}
                disabled={disabled}
                className={`${commonClasses} ${
                    disabled ? "opacity-50 cursor-not-allowed" : ""
                }`}
                onClick={handleClick}
            >
                {children}
            </button>
        );
    }

    return (
        <NavLink to={href} className={commonClasses} onClick={handleClick}>
            {children}
            <span className="absolute inset-0 w-0 h-full transition-all duration-300 ease-in-out bg-Custom-to-r from-[#eaedee] to-[#cd181f] z-[-1] rounded-full hover:w-full"></span>
        </NavLink>
    );
};

export default CustomButton;
