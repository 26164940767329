import React from "react";
import translator from "../../../Components/Extra/Translation/Translate";
import CardHome from "../../component/cards/CustomCard";

export default function section4() {
    const { translate } = translator();
    return (
        <div className="bg-clisha-904">
            <section className="flex flex-col mx-8 lg:!mx-32 py-10">
                <div className="w-full mx-auto flex md:!flex-row flex-col md!:gap-10 gap-5 md!:pt-[35px] ">
                    <div
                        data-aos="flip-right"
                        className="md:!w-1/3 w-full flex"
                    >
                        <CardHome
                            title={translate("HomeSection4", "Title1")}
                            subTitle={translate("HomeSection4", "Subtitle1")}
                            content={translate("HomeSection4", "Content1")}
                            links="/news"
                            contentLinks={translate(
                                "HomeSection4",
                                "LinkText1",
                            )}
                            classname="bg-clisha-902"
                        />
                    </div>
                    <div
                        data-aos="flip-right"
                        className="md:!w-1/3 w-full flex"
                    >
                        <CardHome
                            title={translate("HomeSection4", "Title2")}
                            subTitle={translate("HomeSection4", "Subtitle1")}
                            content={translate("HomeSection4", "Content2")}
                            links="/faq"
                            contentLinks={translate(
                                "HomeSection4",
                                "LinkText2",
                            )}
                            classname="bg-clisha-902 "
                        />
                    </div>
                    <div
                        data-aos="flip-right"
                        className="md:!w-1/3 w-full flex"
                    >
                        <CardHome
                            title={translate("HomeSection4", "Title3")}
                            subTitle={translate("HomeSection4", "Subtitle3")}
                            content={translate("HomeSection4", "Content3")}
                            links="/affiliations"
                            contentLinks={translate(
                                "HomeSection4",
                                "LinkText3",
                            )}
                            classname="bg-clisha-902 "
                        />
                    </div>
                </div>
            </section>
        </div>
    );
}
