import UserWallet from "./UserWallet";

export interface ICrypto {
    name: string;
    abbreviation: string;
    status: boolean;
    default_roi: number;
    logo?: File;
    current_price?: number;
    lower_stacking?: number;
    withdrawal_fee?: number;
    withdrawal_fee_type?: string;
    description?: string;
    asset_stacking?: string;
    category?: string[];
    standby_crypto?: boolean;
    purchase?: boolean;
    swap_status?: boolean;
    withdraw_status?: boolean;
    swap_fee_type?: string;
    swap_fee?: number;
    purchase_fees_margin?: number;
    service_fee?: number;
    minimum_deposit?: number;
    minimum_withdraw?: number;
    maximum_deposit?: number;
    monthly_max_withdraw?: number;
}

export default class Crypto {
    id?: number;
    name!: string;
    abbreviation!: string;
    current_price?: number;
    previous_price?: number;
    default_roi!: number;
    logo!: string;
    created_at?: Date;
    updated_at?: Date;
    status?: boolean;
    user_wallet?: UserWallet[];
    type?: string;
    lower_stacking?: number;
    withdrawal_fee?: number;
    withdrawal_fee_type?: string;
    description?: string;
    purchase_fees_margin?: number;
    service_fee?: number;
    minimum_deposit?: number;
    minimum_withdraw?: number;
    maximum_deposit?: number;
    deposit_address?: {
        memo?: string;
        address?: string;
        network?: string;
    };

    is_deposit_kalypay?: boolean;
    standby_crypto?: boolean;
    purchase?: boolean;
    is_new?: boolean;
    swap_status?: boolean;
    withdraw_status?: boolean;
    deposit_status?: boolean;
    is_stable_coin?: boolean;
    swap_fee_type?: WithdrawalFeeType;
    swap_fee?: number;
    rank!: number;
    price_change_percentage_24?: number;
    coingeckoid?: number;
    rank_earning!: number;
    show_earning?: boolean;
    monthly_max_withdraw?: number;

    constructor(
        name: string,
        abbreviation: string,
        default_roi: number,
        rank: number,
    ) {
        this.name = name;
        this.abbreviation = abbreviation;
        this.default_roi = default_roi;
        this.rank = rank;
    }
}
/* eslint-disable */
export enum WithdrawalFeeType {
    PERCENT = "percent",
    FIX = "fix",
}
/* eslint-enable */ export interface FeeConfig {
    fee: number;
    type: WithdrawalFeeType;
}
