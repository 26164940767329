import MyWalletSvg from "../../Components/Extra/Svg/MyWalletSvg";
import RankSvg from "../../Components/Extra/Svg/RankSvg";
import { ItemListProps } from "./Nav/ItemList";

const AccountMenu: ItemListProps[] = [
    {
        name: "Dashboard.Dashboard",
        path: "/dashboard",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                icon-name="monitor"
                data-lucide="monitor"
                className="lucide lucide-monitor"
            >
                <rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect>
                <line x1="8" y1="21" x2="16" y2="21"></line>
                <line x1="12" y1="17" x2="12" y2="21"></line>
            </svg>
        ),
        childs: [],
    },
    {
        name: "Wallets.My_Wallets",
        path: "/wallets",
        icon: <MyWalletSvg />,
        childs: [],
    },
    {
        name: "Wallets.My_Transactions",
        path: "/transactions",
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-card-list"
                viewBox="0 0 16 16"
            >
                {" "}
                <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />{" "}
                <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />{" "}
            </svg>
        ),
        childs: [],
    },
];

export const InvestissmentMenu: ItemListProps[] = [
    {
        name: "Investissement",
        path: "/",
        icon: <RankSvg />,
        childs: [
            {
                name: "Lending",
                path: "/lending",
                icon: <></>,
                childs: [],
            },
            {
                name: "Nodes",
                path: "/nodes",
                icon: <></>,
                childs: [],
            },
            {
                name: "Staking",
                path: "/staking",
                icon: <></>,
                childs: [],
            },
            {
                name: "Staking Defi",
                path: "/staking-defi",
                icon: <></>,
                childs: [],
            },
        ],
    },
];

export default AccountMenu;
