import React from "react";

import translator from "../../../Components/Extra/Translation/Translate";
import CustomButton from "../../component/Bouton/CustomButton";

export type ContactItemProps = {
    styleBtn: string;
};

export default function ContactUs({ styleBtn }: ContactItemProps) {
    const { translate } = translator();
    return (
        <CustomButton href="" className={styleBtn}>
            {translate("CONTACT_US", "contact_us")}
        </CustomButton>
    );
}
