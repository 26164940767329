import { NavLink } from "react-router-dom";
import PachBoosterSvg from "../../Components/Extra/Svg/PachBoosterSvg";

import translator from "../../Components/Extra/Translation/Translate";

import AccountMenu, { InvestissmentMenu } from "./MenuList";
import ItemListComponent from "./Nav/ItemList";

const Side = () => {
    const { translate } = translator();

    return (
        <nav className="side-nav intro-x mdg-side-nav h-screen overflow-y-scroll scrollbar-hidden pt-[60px]">
            <div className="mb-6"></div>
            <div className="mb-2 text-white ">Manage</div>
            <ul>
                {AccountMenu.map((menu, i) => (
                    <ItemListComponent
                        key={i}
                        name={menu.name}
                        icon={menu.icon}
                        path={menu.path}
                        childs={menu.childs}
                    />
                ))}
                <li className="side-nav__devider my-6"></li>
                <div className="mb-2 text-white ">Finances</div>
                {InvestissmentMenu.map((menu, i) => (
                    <ItemListComponent
                        key={i}
                        name={menu.name}
                        icon={menu.icon}
                        path={menu.path}
                        childs={menu.childs}
                    />
                ))}

                <li>
                    <NavLink to="/services" className="side-menu">
                        <div className="side-menu__icon">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                icon-name="list"
                                data-lucide="list"
                                className="lucide lucide-list"
                            >
                                <line x1="8" y1="6" x2="21" y2="6"></line>
                                <line x1="8" y1="12" x2="21" y2="12"></line>
                                <line x1="8" y1="18" x2="21" y2="18"></line>
                                <line x1="3" y1="6" x2="3.01" y2="6"></line>
                                <line x1="3" y1="12" x2="3.01" y2="12"></line>
                                <line x1="3" y1="18" x2="3.01" y2="18"></line>
                            </svg>
                        </div>
                        <div className="side-menu__title">Mes Services</div>
                    </NavLink>
                </li>
                <li className="side-nav__devider my-6"></li>
                <div className="mb-2 text-white ">Supports</div>
                <li>
                    <NavLink
                        to="/loyalty"
                        className={(navData) =>
                            navData.isActive
                                ? "side-menu side-menu--active"
                                : "side-menu"
                        }
                    >
                        <div className="side-menu__icon">
                            <PachBoosterSvg />
                        </div>
                        <div className="side-menu__title">
                            {translate("Admin_Affiliation", "Loyalty")}
                        </div>
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/settings/general"
                        className={(navData) =>
                            navData.isActive
                                ? "side-menu side-menu--active"
                                : "side-menu"
                        }
                    >
                        <div className="side-menu__icon">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                icon-name="settings"
                                data-lucide="settings"
                                className="lucide lucide-settings"
                            >
                                <path d="M12.22 2h-.44a2 2 0 00-2 2v.18a2 2 0 01-1 1.73l-.43.25a2 2 0 01-2 0l-.15-.08a2 2 0 00-2.73.73l-.22.38a2 2 0 00.73 2.73l.15.1a2 2 0 011 1.72v.51a2 2 0 01-1 1.74l-.15.09a2 2 0 00-.73 2.73l.22.38a2 2 0 002.73.73l.15-.08a2 2 0 012 0l.43.25a2 2 0 011 1.73V20a2 2 0 002 2h.44a2 2 0 002-2v-.18a2 2 0 011-1.73l.43-.25a2 2 0 012 0l.15.08a2 2 0 002.73-.73l.22-.39a2 2 0 00-.73-2.73l-.15-.08a2 2 0 01-1-1.74v-.5a2 2 0 011-1.74l.15-.09a2 2 0 00.73-2.73l-.22-.38a2 2 0 00-2.73-.73l-.15.08a2 2 0 01-2 0l-.43-.25a2 2 0 01-1-1.73V4a2 2 0 00-2-2z"></path>
                                <circle cx="12" cy="12" r="3"></circle>
                            </svg>
                        </div>
                        <div className="side-menu__title">
                            {translate("Profil", "Setting_Security")}
                        </div>
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        to="/governances"
                        className={(navData) =>
                            navData.isActive
                                ? "side-menu side-menu--active"
                                : "side-menu"
                        }
                    >
                        <div className="side-menu__icon">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                fill="none"
                                className="text-white icon-gouvernance"
                            >
                                <path d="M18 13h-.68l-2 2h1.91L19 17H5l1.78-2h2.05l-2-2H6l-3 3v4c0 1.1.89 2 1.99 2H19c1.1 0 2-.89 2-2v-4l-3-3zm-1-5.05l-4.95 4.95-3.54-3.54 4.95-4.95L17 7.95zm-4.24-5.66L6.39 8.66c-.39.39-.39 1.02 0 1.41l4.95 4.95c.39.39 1.02.39 1.41 0l6.36-6.36c.39-.39.39-1.02 0-1.41L14.16 2.3c-.38-.4-1.01-.4-1.4-.01z" />
                            </svg>
                        </div>
                        <div className="side-menu__title">
                            {translate("Governance", "Governance")}
                        </div>
                    </NavLink>
                </li>
                <li>
                    <a
                        href="https://clishanodes.com/faq/"
                        target="_blank"
                        className="side-menu"
                    >
                        <div className="side-menu__icon">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-help-circle"
                            >
                                <circle cx="12" cy="12" r="10"></circle>
                                <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
                                <line x1="12" y1="17" x2="12.01" y2="17"></line>
                            </svg>
                        </div>
                        <div className="side-menu__title">
                            {translate("Setting_Option", "Help_Center")}
                        </div>
                    </a>
                </li>
                <li className="my-6"></li>
            </ul>
        </nav>
    );
};

export default Side;
