import { useRoutes } from "react-router-dom";

import AccountRoutes from "./AccountRoutes";
import AdminRoutes from "./AdminRoutes";
import PublicRoutes from "./PublicRoutes";
import { LandingRoutes } from "../Landing/route/route";

const AppRoutes = () => {
    const routes = useRoutes([
        ...LandingRoutes,
        ...PublicRoutes,
        ...AccountRoutes,
        ...AdminRoutes,
    ]);

    return <>{routes}</>;
};

export default AppRoutes;
