import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Scrollspy from "react-scrollspy";
import translator from "../../Components/Extra/Translation/Translate";
import Banner from "../component/banner";
import CustomButton from "../component/Bouton/CustomButton";
import BuySecondSection from "../ui/Buy/section2";
import BuyFourthSection from "../ui/Buy/section4";
import BuyFifthSection from "../ui/Buy/Section5";
import HelmetComponent from "../component/HelmetComponent";

export default function Buys() {
    const { translate } = translator();
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [location]);

    return (
        <>
            <HelmetComponent
                title={translate("SEO", "Buy_title")}
                description={translate("SEO", "Buy_desc")}
                link="/buys"
                keywords={translate("SEO", "Buy_keywords")}
                imageCard="/dist/image/clishanodes-cards-1024x1024.png"
                largeTwitterCard="/dist/image/clishanodes-cards-1024x1024.png"
                addPostfixTitle={true}
                noIndex={false}
            ></HelmetComponent>
            <Banner
                title={translate("BUY", "PAGE_TITLE")}
                description={translate("BUY", "PAGE_SUBTITLE")}
                button={
                    <CustomButton
                        href="/buy"
                        className="text-base font-semibold px-4 py-2 text-white border-transparent rounded-xl bg-[#263238] mt-8"
                    >
                        {translate("GENERAL", "BUY_NOW")}
                    </CustomButton>
                }
            />
            <Scrollspy
                items={["sectionSecond", "sectionThird", "assets"]}
                currentClassName="is-current"
                offset={-100}
            >
                <div id="sectionSecond">
                    <BuySecondSection />
                </div>
                <div id="sectionThird">
                    <BuyFourthSection />
                </div>
                <div id="assets">
                    <BuyFifthSection />
                </div>
            </Scrollspy>
        </>
    );
}
