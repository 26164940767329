import React from "react";

import translator from "../../../Components/Extra/Translation/Translate";
import Image1 from "../../Image/Image1.jpeg";
import Image2 from "../../Image/Image2.jpeg";
import ContactUs from "../buttons/contact-us";
import StartNow from "../buttons/start-now";

export default function Section2() {
    const { translate } = translator();
    return (
        <div className="bg-clisha-905">
            <section className="flex flex-col mx-8 md:pt-[250px] pt-[100px] lg:mx-32">
                <div className="w-full flex flex-col lg:flex-row justify-between gap-24 items-center mb-16">
                    <div data-aos="fade-up" className="md:!w-1/2 mt-8  lg:mt-0">
                        <img
                            src={Image1}
                            alt="Description de l'image"
                            className="rounded-3xl"
                        />
                    </div>

                    <div
                        data-aos="fade-down"
                        className=" md:!w-1/2 ml-0 lg:-ml-40 lg:mt-0"
                    >
                        <h2 className="text-3xl font-bold mb-4 text-black  -mt-2">
                            {translate("WHITE_LABEL", "section2_div1_h2")}
                        </h2>
                        <p className="text-base md:!text-base  mb-4 text-black ">
                            {translate("WHITE_LABEL", "section2_div1_p")}
                        </p>
                        <StartNow styleBtn="text-base font-semibold px-4 py-2  text-white  rounded-xl bg-clisha-600 mt-8" />
                    </div>
                </div>

                <div className="flex flex-col lg:flex-row justify-between items-center gap-24 md:mt-20 ">
                    <div
                        data-aos="fade-down"
                        className="md:!w-1/2 ml-0  lg:-ml-40 mt-8 lg:mt-0"
                    >
                        <h2 className="text-3xl font-bold mb-4 text-black  -mt-2">
                            {translate("WHITE_LABEL", "section2_div2_h2")}
                        </h2>
                        <p className="text-base md:!text-base  mb-4 text-black ">
                            {translate("WHITE_LABEL", "section2_div2_p")}
                        </p>
                        <ContactUs styleBtn="text-base font-semibold px-4 py-2 border-transparent text-white  rounded-xl bg-clisha-600 mt-8" />
                    </div>
                    <div
                        data-aos="fade-up"
                        className="md:!w-1/2 mt-8 mb-8 lg:mt-0"
                    >
                        <img
                            src={Image2}
                            alt="Description de l'image"
                            className="rounded-3xl"
                        />
                    </div>
                </div>

                <div
                    data-aos="flip-left"
                    className="md:mt-32 text-base  md:!text-lg  text-black text-center "
                >
                    <p>{translate("WHITE_LABEL", "section2_div3_p")}</p>
                </div>
            </section>
        </div>
    );
}
