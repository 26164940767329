import React from "react";
import CardHome from "../../component/cards/CustomCard";
import laptopImg from "../../Image/HomeLaptop.png";
import translator from "../../../Components/Extra/Translation/Translate";

export default function section4() {
    const { translate } = translator();
    return (
        <div>
            <section className="bg-clisha-904 py-32 space-y-10">
                <div className="text-center">
                    <h1 className="text-clisha-500 lg:text-3xl text-2xl font-bold">
                        {translate("COMPANY", "section4_div3_h1")}
                    </h1>
                    <p className="text-clisha-903 lg:text-2xl text-xl">
                        {translate("COMPANY", "section4_div3_p")}
                    </p>
                </div>

                <div className="lg:mx-32 mx-8 gap-5 md:flex lg:justify-between lg:space-x-5 ">
                    <div
                        data-aos="flip-right"
                        className="md:w-[50%] flex flex-col justify-between"
                    >
                        <CardHome
                            title={translate("COMPANY", "section4_card1_title")}
                            subTitle={translate(
                                "COMPANY",
                                "section4_card1_subTitle",
                            )}
                            content={translate(
                                "COMPANY",
                                "section4_card1_content",
                            )}
                            links="#"
                            contentLinks={translate(
                                "COMPANY",
                                "section4_card1_contentLinks",
                            )}
                            image={laptopImg}
                        />
                    </div>

                    <div className="md:w-[50%] flex flex-col justify-between space-y-5">
                        <div data-aos="flip-left">
                            <CardHome
                                title={translate(
                                    "COMPANY",
                                    "section4_card2_title",
                                )}
                                subTitle={translate(
                                    "COMPANY",
                                    "section4_card2_subTitle",
                                )}
                                content={translate(
                                    "COMPANY",
                                    "section4_card2_content",
                                )}
                                links="#"
                                contentLinks={translate(
                                    "COMPANY",
                                    "section4_card2_contentLinks",
                                )}
                            />
                        </div>
                        <div data-aos="flip-left">
                            <CardHome
                                title={translate(
                                    "COMPANY",
                                    "section4_card3_title",
                                )}
                                subTitle={translate(
                                    "COMPANY",
                                    "section4_card3_subTitle",
                                )}
                                content={translate(
                                    "COMPANY",
                                    "section4_card3_content",
                                )}
                                links="#"
                                contentLinks={translate(
                                    "COMPANY",
                                    "section4_card3_contentLinks",
                                )}
                            />
                        </div>
                        <div data-aos="flip-left">
                            <CardHome
                                title={translate(
                                    "COMPANY",
                                    "section4_card4_title",
                                )}
                                subTitle={translate(
                                    "COMPANY",
                                    "section4_card4_subTitle",
                                )}
                                content={translate(
                                    "COMPANY",
                                    "section4_card4_content",
                                )}
                                links="#"
                                contentLinks={translate(
                                    "COMPANY",
                                    "section4_card4_contentLinks",
                                )}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
