import { NavLink } from "react-router-dom";
import ChevronDownSvg from "../../../Components/Extra/Svg/ChevronDownSvg";
import translator from "../../../Components/Extra/Translation/Translate";

export type ItemListProps = {
    name: string;
    icon: any;
    path: string;
    childs: ItemListProps[];
};

const ItemListMobile = ({ name, icon, path, childs }: ItemListProps) => {
    const expanded = false;
    const { translate } = translator();

    const toggleExanded = (e: any) => {
        if (childs && childs.length > 0) {
            e.preventDefault();
            return false;
        }
    };

    return (
        <>
            <li>
                <NavLink
                    to={
                        childs && childs.length > 0
                            ? `javascript:void(0)`
                            : path
                    }
                    className={(navData) =>
                        navData.isActive
                            ? expanded
                                ? `menu menu--active cursor-default ${
                                      !childs || childs.length === 0
                                          ? "single-menu"
                                          : ""
                                  }`
                                : `menu menu--active cursor-default ${
                                      !childs || childs.length === 0
                                          ? "single-menu"
                                          : ""
                                  }`
                            : `menu cursor-default ${
                                  !childs || childs.length === 0
                                      ? "single-menu"
                                      : ""
                              }`
                    }
                    onClick={(e) => {
                        toggleExanded(e);
                    }}
                >
                    <div className="menu__icon text-white">{icon}</div>
                    <div className="menu__title text-white justify-between">
                        {translate(".", name)}
                        {childs && childs.length > 0 && <ChevronDownSvg />}
                    </div>
                </NavLink>
                {childs && childs.length > 0 && (
                    <ul className={expanded ? "menu__sub-open" : ""}>
                        {childs.map((child, i) => (
                            <ItemListMobile
                                key={i}
                                name={child.name}
                                icon={child.icon}
                                path={child.path}
                                childs={child.childs}
                            ></ItemListMobile>
                        ))}
                    </ul>
                )}
            </li>
        </>
    );
};

export default ItemListMobile;
