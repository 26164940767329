import React from "react";

import translator from "../../../Components/Extra/Translation/Translate";
import ContactUs from "../buttons/contact-us";

export default function Section4() {
    const { translate } = translator();
    return (
        <>
            <div className="bg-clisha-904">
                <section className="flex justify-center items-center mx-8 py-[100px] lg:mx-32">
                    <div className="max-w-screen-xl mx-auto w-full text-center">
                        <div className="md:mt-[20px] py-24" data-aos="zoom-in">
                            <h2 className="text-clisha-903 font-bold md:font-bold  md:!text-4xl text-2xl pb-5">
                                {translate("WHITE_LABEL", "section4_h2")}
                            </h2>

                            <div className="flex flex-wrap md:gap-0 leading-normal gap-3 justify-center items-center space-x-2">
                                <ContactUs styleBtn="text-base font-semibold px-4 py-2 text-clisha-904  border-transparent rounded-xl bg-clisha-500  mt-8" />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}
