import React from "react";
import translator from "../../../Components/Extra/Translation/Translate";
import Banner from "../../component/banner";
import CreateAccountButton from "../buttons/create-account";

export default function Section1() {
    const { translate } = translator();
    return (
        <>
            <Banner
                title={translate("HomeSection1", "Title")}
                subtitle={translate("HomeSection1", "Subtitle")}
                description={translate("HomeSection1", "Description")}
                button={<CreateAccountButton />}
            />
        </>
    );
}
