import React from "react";
import { RouteObject, useRoutes } from "react-router-dom";
import Home from "../pages/home";
import Base from "../layout/base";
import Legal from "../pages/legal-notice";
import Condition from "../pages/condition";
import Privacy from "../pages/privacy-policy";
import Buys from "../pages/buy";
import Earn from "../pages/earn";
import Entreprise from "../pages/entreprises";
import Conseillers from "../pages/conseillers";
import Echange from "../pages/echange";
import WhiteLabel from "../pages/white-label";
import Faq from "../pages/faq";
import News from "../pages/news";
import Affiliation from "../pages/affiliation";

export const LandingRoutes: RouteObject[] = [
    {
        element: <Base />,
        children: [
            {
                path: "/",
                element: <Home />,
            },
            {
                path: "/legal-notice",
                element: <Legal />,
            },
            {
                path: "/condition",
                element: <Condition />,
            },
            {
                path: "/privacy-policy",
                element: <Privacy />,
            },
            {
                path: "/earns",
                element: <Earn />,
            },
            {
                path: "/buys",
                element: <Buys />,
            },
            {
                path: "/entreprises",
                element: <Entreprise />,
            },
            {
                path: "/conseillers-financiers",
                element: <Conseillers />,
            },
            {
                path: "/exchanges",
                element: <Echange />,
            },
            {
                path: "/white-label",
                element: <WhiteLabel />,
            },
            {
                path: "/faq",
                element: <Faq />,
            },
            {
                path: "/news",
                element: <News />,
            },
            {
                path: "/affiliations",
                element: <Affiliation />,
            },
        ],
    },
];

export const LandingRouteSsr = () => {
    const routes = useRoutes([...LandingRoutes]);

    return <>{routes}</>;
};
