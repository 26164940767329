import React from "react";

import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUp } from "@fortawesome/free-solid-svg-icons";

const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 1000) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <button
            id="backToTopBtn"
            onClick={scrollToTop}
            style={{
                display: isVisible ? "block" : "none",
            }}
            className="bg-clisha-600  rounded-xl p-2 right-3 bottom-5 fixed shadow-orange-500/60 shadow-md"
        >
            <FontAwesomeIcon
                icon={faCircleUp}
                className="text-clisha-500 text-2xl"
            />
        </button>
    );
};

export default ScrollToTopButton;
