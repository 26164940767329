import React from "react";

interface BannerProps {
    title: string;
    description: string;
    subtitle?: string;
    button?: React.ReactNode;
}

export default function Banner({
    title,
    description,
    button,
    subtitle,
}: BannerProps) {
    return (
        <div className="bg-custom ">
            <section className="flex justify-center items-center mx-8 py-[100px] lg:mx-32">
                <div className="max-w-screen-xl mx-auto w-full text-center">
                    <div className="md:mt-[20px] py-24" data-aos="zoom-in">
                        <h2 className="text-white font-bold md:font-bold  md:text-5xl text-4xl pb-5">
                            {title}
                        </h2>
                        {subtitle && (
                            <h6 className="text-white font-bold md:font-bold md:text-5xl text-2xl pb-5">
                                {subtitle}
                            </h6>
                        )}

                        <p className="text-white md:text-2xl text-xl">
                            {description}
                        </p>
                        {button && (
                            <div className="flex flex-wrap md:gap-0 leading-normal gap-3 justify-center items-center space-x-2">
                                {button}
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </div>
    );
}
