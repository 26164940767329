import React from "react";

import translator from "../../../Components/Extra/Translation/Translate";
import AccessTheDashboardButton from "../buttons/access-the-dashboard";

export default function AffiliationThirdSection() {
    const { translate } = translator();
    return (
        <>
            <div className="bg-clisha-600 py-32">
                <section className="flex justify-center items-center mx-8  lg:mx-32">
                    <div className="max-w-screen-xl mx-auto w-full text-center">
                        <div className="" data-aos="zoom-in">
                            <h2 className="text-white font-bold md:font-bold  md:text-3xl text-3xl pb-5">
                                {translate("AFFILIATION", "SECTION_3_TITLE")}
                            </h2>

                            <p className="text-white md:text-xl text-xl">
                                {translate("AFFILIATION", "SECTION_3_CONTENT")}
                            </p>
                            <div className="flex flex-wrap md:gap-0 leading-normal gap-3 justify-center items-center space-x-2">
                                <AccessTheDashboardButton yellow={true} />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}
