import React from "react";
import CardHome from "../../component/cards/CustomCard";

import translator from "../../../Components/Extra/Translation/Translate";

export default function AffiliationSecondSection() {
    const { translate } = translator();
    return (
        <div>
            <section className="bg-clisha-904 py-32 space-y-10">
                <div className="text-center">
                    <h1 className="text-clisha-500 lg:text-3xl text-2xl font-bold">
                        {translate("AFFILIATION", "SECTION_2_TITLE")}
                    </h1>
                    <p className="text-clisha-903 lg:text-2xl text-xl">
                        {translate("AFFILIATION", "SECTION_2_CONTENT_TITLE")}
                    </p>
                </div>

                <div className="lg:mx-32 mx-8 md:flex lg:justify-between lg:space-x-5 ">
                    <div
                        className="md:w-[50%] flex flex-col justify-between"
                        data-aos="fade-right"
                    >
                        <CardHome
                            title={translate("AFFILIATION", "CARD_1_TITLE")}
                            subTitle={translate(
                                "AFFILIATION",
                                "CARD_1_SUBTITLE",
                            )}
                            content={translate("AFFILIATION", "CARD_1_CONTENT")}
                        />
                    </div>

                    <div className="md:w-[50%]" data-aos="fade-left">
                        <CardHome
                            title={translate("AFFILIATION", "CARD_2_TITLE")}
                            subTitle={translate(
                                "AFFILIATION",
                                "CARD_2_SUBTITLE",
                            )}
                            content={translate("AFFILIATION", "CARD_2_CONTENT")}
                        />
                    </div>
                </div>
            </section>
        </div>
    );
}
