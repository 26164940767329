import React from "react";
import AssetList from "../assets/asset-table";

export default function BuyFifthSection() {
    return (
        <div className="bg-white py-32">
            <section className="flex justify-center items-center mx-2 lg:!mx-32">
                <div
                    data-aos="zoom-in"
                    className="min-w-screen-xl mx-auto w-full "
                >
                    <AssetList />
                </div>
            </section>
        </div>
    );
}
