import BonusChartData from "../../Models/BonusChartData";
import { FeeConfig, WithdrawalFeeType } from "../../Models/Crypto";
import { DepositResponse, IDeposit } from "../../Models/Deposit";
import { ISwap } from "../../Models/Swap";
import { UserCryptoWallet } from "../../Models/UserWallet";
import axios from "../AxiosInstance";
import UtilsService from "../UtilsService";

const mapBonusChartData = (item: any): BonusChartData => {
    const bonus_chart_data = new BonusChartData(
        item.roi_stacking,
        item.roi_knt,
        item.rank_bonus,
        item.pool_bonus,
        item.affiliation,
    );
    return bonus_chart_data;
};

const getUserWallet = async (): Promise<Array<UserCryptoWallet>> => {
    return axios
        .get(`/user-wallet`)
        .then(({ data }) => {
            const wallets: UserCryptoWallet[] = data as UserCryptoWallet[];

            return wallets;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getUserWalletWithNetwork = async (): Promise<Array<UserCryptoWallet>> => {
    return axios
        .get(`/user-wallet/with-networks`)
        .then(({ data }) => {
            const wallets: UserCryptoWallet[] = data as UserCryptoWallet[];

            return wallets;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getByUserFromAdmin = async (
    uid: string,
): Promise<Array<UserCryptoWallet>> => {
    return axios
        .get(`/user-wallet/get-by-user/${uid}`)
        .then(({ data }) => {
            const wallets: UserCryptoWallet[] = data as UserCryptoWallet[];

            return wallets;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getAllCryptoWithAllUserWallet = async (): Promise<any> => {
    return axios
        .get(`/user-wallet/list-detail`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const swap = async (swap: ISwap): Promise<string> => {
    return axios
        .post("/user-wallet/swap", { ...swap })
        .then((res) => {
            const { data } = res;
            if (data.code === "SUCCESS") {
                return Promise.resolve(data.message);
            }
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getUserWalletSolde = async (abbr: string): Promise<number> => {
    return axios
        .get(`/user-wallet/get-user-wallet-solde/${abbr}`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getTotalKNT = async (): Promise<number> => {
    return axios
        .get(`/user-wallet/get-total-knt`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getFee = (amount: number, fee_config: FeeConfig) => {
    if (fee_config.type === WithdrawalFeeType.PERCENT) {
        const fee = (amount * fee_config.fee) / 100;
        return fee;
    }
    return fee_config.fee;
};

const convertAmountToUSD = (amount: number, usd_price: number) => {
    const amount_converted = amount * usd_price;
    return amount_converted;
};

const convertAmountUSDToOther = (amount_usd: number, price: number) => {
    const amount_converted = amount_usd / price;
    return amount_converted;
};

const getBonusChartData = async (): Promise<BonusChartData> => {
    return axios
        .get(`/wallet-history/get-bonus-chart-data`)
        .then(({ data }) => {
            const item: BonusChartData = mapBonusChartData(data);

            return item;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const deposit = async (
    deposit: IDeposit,
    minDeposit?: number,
): Promise<DepositResponse> => {
    if (minDeposit && deposit.amount < minDeposit) {
        return Promise.reject(
            `A minimum deposit of ${minDeposit} ${deposit.wallet} is required`,
        );
    }
    return axios
        .post("/deposit", { ...deposit })
        .then((res) => {
            const data = res.data as DepositResponse;
            if (data.code === "ORDER_CREATED" || data.code === "success") {
                return Promise.resolve(data);
            } else {
                return Promise.reject(
                    UtilsService.getAxiosErrorMessage(data.message),
                );
            }
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getGlobalBonusChartData = async (): Promise<BonusChartData> => {
    return axios
        .get(`/wallet-history/get-global-bonus-chart-data`)
        .then(({ data }) => {
            const item: BonusChartData = mapBonusChartData(data);

            return item;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const WalletService = {
    getUserWallet,
    swap,
    getUserWalletSolde,
    getTotalKNT,
    getFee,
    convertAmountToUSD,
    convertAmountUSDToOther,
    getBonusChartData,
    deposit,
    getAllCryptoWithAllUserWallet,
    getByUserFromAdmin,
    getGlobalBonusChartData,
    getUserWalletWithNetwork,
};

export default WalletService;
