import React from "react";
import CardHome from "../../component/cards/CustomCard";
import laptopImg from "../../Image/HomeLaptop.png";
import translator from "../../../Components/Extra/Translation/Translate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faToolbox, faUsers } from "@fortawesome/free-solid-svg-icons";
import { faBitcoin } from "@fortawesome/free-brands-svg-icons";
import CounterUp from "../../../hooks/useCounterUp";

export default function section2() {
    const { translate } = translator();
    return (
        <div className="bg-clisha-904 py-32">
            <section className="flex flex-col mx-8 lg:!mx-32">
                <div className="min-w-screen-xl mx-auto w-full ">
                    <div className="text-center px-4">
                        <h1 className="text-clisha-500 lg:text-3xl text-2xl font-bold">
                            {translate("HomeSection2", "Title")}
                        </h1>
                        <p className="text-clisha-903 lg:text-2xl text-xl">
                            {translate("HomeSection2", "Description")}
                        </p>
                    </div>

                    <div className="md:flex justify-center items-stretch gap-20 py-[35px] ">
                        <div
                            data-aos="fade-up-right"
                            className="flex flex-col py-5 justify-center items-center md:!items-start w-full "
                        >
                            <CardHome
                                title={translate("HomeSection2", "Title1")}
                                subTitle={translate(
                                    "HomeSection2",
                                    "Subtitle1",
                                )}
                                content={translate("HomeSection2", "Content1")}
                                links="#"
                                contentLinks={translate(
                                    "HomeSection2",
                                    "LinkText1",
                                )}
                                image={laptopImg}
                            />
                        </div>

                        <div className="relative flex flex-col justify-center gap-7 w-full ">
                            <div data-aos="flip-left">
                                <CardHome
                                    title={translate("HomeSection2", "Title2")}
                                    subTitle={translate(
                                        "HomeSection2",
                                        "Subtitle2",
                                    )}
                                    content={translate(
                                        "HomeSection2",
                                        "Content2",
                                    )}
                                    links="/buys#assets"
                                    contentLinks={translate(
                                        "HomeSection2",
                                        "LinkText2",
                                    )}
                                />
                            </div>

                            <div data-aos="flip-left">
                                <CardHome
                                    title={translate("HomeSection2", "Title3")}
                                    subTitle={translate(
                                        "HomeSection2",
                                        "Subtitle3",
                                    )}
                                    content={translate(
                                        "HomeSection2",
                                        "Content3",
                                    )}
                                    links="/earns"
                                    contentLinks={translate(
                                        "HomeSection2",
                                        "LinkText3",
                                    )}
                                />
                            </div>

                            <div data-aos="flip-left">
                                <CardHome
                                    title={translate("HomeSection2", "Title4")}
                                    subTitle={translate(
                                        "HomeSection2",
                                        "Subtitle4",
                                    )}
                                    content={translate(
                                        "HomeSection2",
                                        "Content4",
                                    )}
                                    links="/register"
                                    contentLinks={translate(
                                        "HomeSection2",
                                        "LinkText4",
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div data-aos="zoom-out" className="mt-5">
                        <div className="grid lg:!grid-cols-4 grid-cols-1 gap-x-6 gap-y-12 divide-x divide-gray-300">
                            <div className="text-center">
                                <FontAwesomeIcon
                                    icon={faToolbox}
                                    className="text-3xl text-clisha-500"
                                />
                                <h3 className="text-2xl font-extrabold text-gray-500 mt-5">
                                    <CounterUp delay={10} time={5000}>
                                        25
                                    </CounterUp>
                                    <span className="pl-1">millions+</span>
                                </h3>
                                <p className="lg:text-2xl text-xl text-clisha-500 font-semibold mt-5">
                                    {" "}
                                    {translate("HomeSection2", "Title5")}
                                </p>
                            </div>
                            <div className="text-center">
                                <FontAwesomeIcon
                                    icon={faUsers}
                                    className="text-3xl text-clisha-500"
                                />
                                <h3 className="text-2xl font-extrabold text-gray-500 mt-5">
                                    <CounterUp delay={10} time={5000}>
                                        55
                                    </CounterUp>
                                    <span className="pl-1">K+</span>
                                </h3>
                                <p className="lg:text-2xl text-xl text-clisha-500 font-semibold mt-5">
                                    {" "}
                                    {translate("HomeSection2", "Title6")}
                                </p>
                            </div>
                            <div className="text-center">
                                <FontAwesomeIcon
                                    icon={faBitcoin}
                                    className="text-3xl text-clisha-500"
                                />
                                <h3 className="text-2xl font-extrabold text-gray-500 mt-5">
                                    <CounterUp delay={10} time={5000}>
                                        60
                                    </CounterUp>
                                    <span className="pl-1">+</span>
                                </h3>
                                <p className="lg:text-2xl text-xl text-clisha-500 font-semibold mt-5">
                                    {translate("HomeSection2", "Title7")}
                                </p>
                            </div>
                            <div className="text-center">
                                <FontAwesomeIcon
                                    icon={faGlobe}
                                    className="text-3xl text-clisha-500"
                                />
                                <h3 className="text-2xl font-extrabold text-gray-500 mt-5">
                                    <CounterUp delay={10} time={5000}>
                                        120
                                    </CounterUp>
                                    <span className="pl-1">+</span>
                                </h3>
                                <p className="lg:text-2xl text-xl text-clisha-500 font-semibold mt-5">
                                    {translate("HomeSection2", "Title8")}
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* <div
                    data-aos="flip-left"
                    className="md:flex lg:justify-between gap-10 mt-[30px]"
                >
                    <div className="flex flex-col lg:items-center lg:justify-center mt-[50px]">
                        <h1 className="lg:text-2xl text-xl text-clisha-500 font-bold">
                            {translate("HomeSection2", "Title5")}
                        </h1>
                        <p className="text-base mt-2 text-clisha-903 ">
                            {translate("HomeSection2", "Description5")}
                        </p>
                    </div>
                    <hr className="bg-clisha-903 border-none w-[1px] h-40 lg:block hidden bg-opacity-20 " />
                    <div className="flex flex-col lg:items-center lg:justify-center mt-[50px]">
                        <h1 className="lg:text-2xl text-xl text-clisha-500 font-bold">
                            {translate("HomeSection2", "Title6")}
                        </h1>
                        <p className="text-base mt-2 text-clisha-903 ">
                            {translate("HomeSection2", "Description6")}
                        </p>
                    </div>
                    <hr className="bg-clisha-903 border-none w-[1px] h-40 lg:block hidden bg-opacity-20" />
                    <div className="flex flex-col lg:items-center lg:justify-center mt-[50px]">
                        <h1 className="lg:text-2xl text-xl text-clisha-500 font-bold">
                            {translate("HomeSection2", "Title7")}
                        </h1>
                        <p className="text-base mt-2 text-clisha-903 ">
                            {translate("HomeSection2", "Description7")}
                        </p>
                    </div>
                    <hr className="bg-clisha-903 border-none w-[1px] h-40 lg:block hidden bg-opacity-20" />
                    <div className="flex flex-col lg:items-center lg:justify-center mt-[50px]">
                        <h1 className="lg:text-2xl text-xl text-clisha-500 font-bold">
                            {translate("HomeSection2", "Title8")}
                        </h1>
                        <p className="text-base mt-2 text-clisha-903 ">
                            {translate("HomeSection2", "Description8")}
                        </p>
                    </div>
                </div> */}
                </div>
            </section>
        </div>
    );
}
