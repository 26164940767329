import React, { useEffect, useState } from "react";
import { useAppSelector } from "../globalState/hook";
import Loader from "../Layouts/Loader/Loader";
import AppAuthentication from "../Services/Authentication/AppAuthentication";
import useAuthentication from "../Services/Authentication/useAuthentication";
import { refreshMyToken } from "../Services/AxiosInstance";

type AuthenticationProviderProps = {
    children?: JSX.Element;
    check?: boolean;
};
const AuthenticationProvider = ({
    children,
    check = true,
}: AuthenticationProviderProps) => {
    const { user } = useAuthentication();
    const { authChecking } = useAppSelector((store) => store.auth);
    const { getInformationAsync } = AppAuthentication();
    const [checkAuthentication, setCheckAuthentication] =
        useState<boolean>(true);

    useEffect(() => {
        if (!user) {
            try {
                refreshMyToken()
                    .then((data) => {
                        if (data) {
                            getInformationAsync()
                                .catch(() => {
                                    console.log("error");
                                })
                                .finally(() => {
                                    setCheckAuthentication(false);
                                });
                        } else {
                            setCheckAuthentication(false);
                        }
                    })
                    .catch(() => {
                        setCheckAuthentication(false);
                    });
            } catch {
                setCheckAuthentication(false);
            }
        } else {
            setCheckAuthentication(false);
        }
    }, [user]);

    if (check) {
        if (!user && (authChecking || checkAuthentication)) {
            return <Loader />;
        }
    }

    return (
        <>
            {!check && <Loader />} {children}
        </>
    );
};

export default AuthenticationProvider;
