import { Outlet } from "react-router";
import Head from "./Head";
import Side from "./Side";
import SideMobile from "./SideMobile";
import LoadingBar from "react-top-loading-bar";
import AssetsProvider from "../../provider/AssetsProvider";
import AppProvider from "../../provider/AppProvider";

const Base = () => {
    return (
        <AppProvider>
            <AssetsProvider>
                <div>
                    <LoadingBar color="#FC9828" progress={0} />
                    <SideMobile />
                    <Head />
                    <div className="mdg-section flex md:mt-0 md-pb-20">
                        <Side />
                        <div className="content mdg-content h-screen overflow-y-scroll w-content !z-0 !rounded-none">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </AssetsProvider>
        </AppProvider>
    );
};

export default Base;
