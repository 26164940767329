import { useAppSelector } from "../../globalState/hook";
export default function useAuthentication() {
    const { user, isAuthenticated } = useAppSelector((state) => state.auth);
    const isAdmin = user && user.scopes && user.scopes.includes("admin");

    return {
        isAdmin,
        user,
        isAuthenticated,
    };
}
