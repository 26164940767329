import React from "react";

import { useMemo } from "react";
import {
    MantineReactTable,
    useMantineReactTable,
    type MRT_ColumnDef,
} from "mantine-react-table";
import UtilsService from "../../../Services/UtilsService";
import translator from "../../../Components/Extra/Translation/Translate";
import useLanguage from "../../../Services/Hooks/useLanguage";
import useAssets from "../../../hooks/useAsset";
import { Asset } from "../../../Models/Asset";
import CustomButton from "../../component/Bouton/CustomButton";
import { Language } from "../../../globalState/reducers/Language.reducer";
import { MRT_Localization_FR } from "mantine-react-table/locales/fr";
import { MRT_Localization_EN } from "mantine-react-table/locales/en";

const AssetList = () => {
    const { assets } = useAssets();
    const { language } = useLanguage();
    const { translate } = translator();

    const columns = useMemo<MRT_ColumnDef<Asset>[]>(
        () => [
            {
                accessorFn: (row) => `${row.name}`,
                id: "name",
                header: translate("GENERAL", "ASSET"),
                size: 150,
                filterVariant: "autocomplete",
                Cell: ({ renderedCellValue, row }) => (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "16px",
                        }}
                    >
                        <img
                            alt="avatar"
                            width={40}
                            src={UtilsService.getPhotoUrl(row.original.logo)}
                            style={{ borderRadius: "50%" }}
                        />
                        <div className="grid grid-cols-1 gap-0">
                            <span className="font-bold">
                                {renderedCellValue}
                            </span>
                            <span className="text-slate-500">
                                {row.original.symbol}
                            </span>
                        </div>
                    </div>
                ),
            },
            {
                accessorKey: "usdPrice",
                enableClickToCopy: true,
                header: translate("GENERAL", "PRICE"),
                size: 100,
                Cell: ({ row }) => (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "right",
                            gap: "16px",
                        }}
                    >
                        <span>
                            {UtilsService.formatAmount(
                                row.original.usdPrice,
                                2,
                            )}
                            $
                        </span>
                    </div>
                ),
            },
            {
                accessorKey: "last24Hrs",
                header: translate("Earning", "Prix_Evo"),
                size: 150,
                filterVariant: "range-slider",

                mantineFilterRangeSliderProps: {
                    color: "indigo",
                    label: (value) =>
                        value?.toLocaleString?.("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        }),
                },
                Cell: ({ cell, row }) => (
                    <div
                        style={{
                            backgroundColor:
                                cell.getValue<number>() < 0
                                    ? "#fc9828"
                                    : cell.getValue<number>() === 0
                                    ? "#fc9828"
                                    : "#00ff00",
                            borderRadius: "4px",
                            color: "#fff",
                            maxWidth: "9ch",
                            padding: "4px",
                            textAlign: "center",
                        }}
                    >
                        {row.original.last24Hrs >= 0 ? "+" : "-"}
                        {` ${Math.abs(row.original.last24Hrs)}%`}
                    </div>
                ),
            },

            {
                accessorKey: "symbol",
                header: translate("GENERAL", ""),
                size: 100,
                Cell: ({ row }) => (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "right",
                            gap: "16px",
                        }}
                    >
                        <CustomButton
                            href={`/buy/${row.original.symbol}`}
                            className="text-base font-semibold px-4 py-2 text-white  border-transparent rounded-xl bg-clisha-700 hover:bg-clisha-500"
                        >
                            {translate("GENERAL", "BUY")}
                        </CustomButton>
                    </div>
                ),
            },
        ],
        [language],
    );

    const table = useMantineReactTable({
        columns,
        data: assets,
        enableFullScreenToggle: false,
        enableColumnFilterModes: true,
        enableColumnOrdering: true,
        enableFacetedValues: true,
        enableGrouping: true,
        enableColumnResizing: false,
        localization:
            language === Language.FR
                ? MRT_Localization_FR
                : MRT_Localization_EN,
        enablePinning: true,
        initialState: { showColumnFilters: false, showGlobalFilter: false },
        paginationDisplayMode: "pages",
        positionToolbarAlertBanner: "bottom",
        mantinePaginationProps: {
            radius: "xl",
            size: "lg",
            sx: {
                ".mantine-Pagination-item": {
                    border: "2px solid #263238", // Customize border color
                    borderRadius: "8px", // Customize border radius
                    padding: "4px", // Add padding if needed
                    "&.mantine-Pagination-item-active": {
                        borderColor: "#007BFF",
                    },
                },
                ".mantine-Pagination-control": {
                    border: "2px solid #a8adaf",
                    borderRadius: "8px",
                    padding: "4px",
                },
            },
            lang: "fr-FR",
        },
        mantineSearchTextInputProps: {
            placeholder: "Recherche",
            sx: {
                border: "2px solid #99a0a7",
                borderRadius: "8px",
                width: "100%",
                "&:focus": {
                    borderColor: "transparent",
                },
            },
        },
    });

    return <MantineReactTable table={table} />;
};

export default AssetList;
