import React from "react";

import translator from "../../../Components/Extra/Translation/Translate";
import CustomButton from "../../component/Bouton/CustomButton";

export default function ExchangeNowButton() {
    const { translate } = translator();

    return (
        <CustomButton
            href="/exhange"
            className="text-base font-semibold px-4 py-2 border-transparent text-white rounded-xl bg-clisha-600 mt-8"
        >
            {translate("GENERAL", "EXCHANGE_NOW")}
        </CustomButton>
    );
}
