import React from "react";
import { clsx } from "@mantine/core";
import translator from "../../../Components/Extra/Translation/Translate";
import CustomButton from "../../component/Bouton/CustomButton";

export default function CreateAccountButton({ yellow = false }) {
    const { translate } = translator();
    return (
        <CustomButton
            href="/register"
            className={clsx(
                " text-base  px-4 py-2 text-white !border-transparent rounded-xl   mt-8",
                {
                    "bg-clisha-600 font-semibold": !yellow,
                    "bg-clisha-500 hover:bg-clisha-600": yellow,
                },
            )}
        >
            {translate("GENERAL", "CREATE_ACCOUNT")}
        </CustomButton>
    );
}
