import loadable from "@loadable/component";

import Base from "../Layouts/Account/Base";

import PrivateRouteProvider from "./RouteProvider/PrivateRouteProvider";
import { EarnType } from "../Models/Earn";

const Logout = loadable(() => import("../Components/Authentication/Logout"));

const Dashboard = loadable(() => import("../Components/Dashboard/Dashboard"));

const PnlAnalysisDashboard = loadable(
    () => import("../Components/Dashboard/PnlAnalysis/PnlAnalysisDashboard"),
);

const Profile = loadable(() => import("../Components/Profil/Profile"));

const ProfileDetail = loadable(
    () => import("../Components/Profil/TabElements/ProfileDetail"),
);
const ProfileEdit = loadable(
    () => import("../Components/Profil/TabElements/ProfileEdit"),
);
const Affiliation = loadable(
    () => import("../Components/Profil/TabElements/Affiliation"),
);
const ProfileSecurity = loadable(
    () => import("../Components/Profil/TabElements/ProfileSecurity"),
);

const Template = loadable(
    () => import("../Components/TemplateWallet/Template"),
);

const Commande = loadable(() => import("../Components/Order/PackboosterOrder"));

const ListTransactions = loadable(
    () => import("../Components/Transactions/ListTransactions"),
);

const Swap = loadable(() => import("../Components/Wallets/Swap"));

const StackingNew = loadable(
    () => import("../Components/Stacking/StackingNew"),
);

const SwpTransfert = loadable(
    () => import("../Components/Wallets/Transaction/SwpTransfert"),
);

const KycIdentity = loadable(() => import("../Components/Kyc/Kyc"));

const Withdraw = loadable(() => import("../Components/Wallets/Withdraw"));

const Deposit = loadable(() => import("../Components/Wallets/Deposit"));

const Detailsrank = loadable(
    () => import("../Components/Advantage/DetailRank/Detailsrank"),
);

const Pool = loadable(() => import("../Components/Advantage/Pool/Pool"));

const UniLevel = loadable(
    () => import("../Components/Advantage/UniLevel/UniLevel"),
);

const AffiliationUser = loadable(
    () => import("../Components/Affiliation/Affiliation"),
);

const Evenment = loadable(() => import("../Components/Evenement/Evenment"));
const Marketing = loadable(() => import("../Components/Marketing/Marketing"));
const Visa = loadable(() => import("../Components/Materiel/Visa"));
const ColdWallet = loadable(() => import("../Components/Materiel/ColdWallet"));

const Fidelity = loadable(() => import("../Components/Fidelity/Fidelity"));
const NewWallet = loadable(() => import("../Components/Wallets/NewWallet"));
const Achat = loadable(() => import("../Components/Wallets/Achat"));

const IndexSecurity = loadable(
    () => import("../Components/SettingSecurity/Security/IndexSecurity"),
);
const IndexSetting = loadable(
    () => import("../Components/SettingSecurity/Setting/IndexSetting"),
);
const SettingSecurity = loadable(
    () => import("../Components/SettingSecurity/SettingSecurity"),
);
const NewStaking = loadable(() => import("../Components/Stacking/NewStaking"));

const Earn = loadable(() => import("../Components/Earn/Earn"));

const Gouvernance = loadable(
    () => import("../Components/Gouvernance/Gouvernance"),
);

const AccountRoutes: any = [
    {
        element: <PrivateRouteProvider />,
        children: [
            {
                path: "logout",
                element: <Logout />,
            },
            {
                element: <Base />,
                children: [
                    {
                        path: "/dashboard",
                        element: <Dashboard />,
                        children: [],
                    },
                    {
                        path: "pnl-analysis",
                        element: <PnlAnalysisDashboard />,
                    },
                    {
                        path: "settingss",
                        children: [
                            {
                                element: <Profile />,
                                children: [
                                    {
                                        path: "profile",
                                        children: [
                                            {
                                                path: "detail",
                                                element: <ProfileDetail />,
                                            },
                                            {
                                                path: "edit",
                                                element: <ProfileEdit />,
                                            },
                                            {
                                                path: "security",
                                                element: <ProfileSecurity />,
                                            },
                                            {
                                                path: "affiliate",
                                                element: <Affiliation />,
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                element: <KycIdentity />,
                                path: "verification-identity",
                                children: [],
                            },
                        ],
                    },
                    {
                        path: "",
                        element: <SettingSecurity />,
                        children: [
                            {
                                path: "settings/general",
                                element: <IndexSetting />,
                            },
                            {
                                path: "settings/security",
                                element: <IndexSecurity />,
                            },
                            {
                                path: "settings/referrals",
                                element: <Affiliation />,
                            },
                            {
                                path: "settings/ecosystem",
                                element: <Evenment />,
                            },
                        ],
                    },
                    {
                        path: "avantage",
                        children: [
                            {
                                path: "uni-level",
                                element: <UniLevel />,
                            },
                            {
                                path: "rank-or-carrer",
                                element: <Detailsrank />,
                            },
                            {
                                path: "pool",
                                element: <Pool />,
                            },
                        ],
                    },
                    {
                        path: "",
                        children: [
                            {
                                path: "",
                                children: [
                                    {
                                        index: true,
                                        path: "wallets",
                                        element: <NewWallet />, // <Wallets />,
                                    },
                                    {
                                        path: "exchange/:origin",
                                        element: <Swap />,
                                    },
                                    {
                                        path: "exchange/",
                                        element: <Swap />,
                                    },
                                    {
                                        path: "deposit/:origin",
                                        element: <Deposit />,
                                    },
                                    {
                                        path: "deposit/",
                                        element: <Deposit />,
                                    },
                                    {
                                        path: "buy/:origin",
                                        element: <Achat />,
                                    },
                                    {
                                        path: "buy/",
                                        element: <Achat />,
                                    },
                                    {
                                        path: "withdraw/:origin",
                                        element: <Withdraw />,
                                    },
                                    {
                                        path: "withdraw/",
                                        element: <Withdraw />,
                                    },
                                    {
                                        path: "swap-transaction",
                                        element: <SwpTransfert />,
                                    },
                                ],
                            },
                            {
                                path: "transactions",
                                element: <ListTransactions />,
                            },
                        ],
                    },
                    {
                        path: "earning",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <Earn />,
                            },
                            {
                                path: "new",
                                element: <StackingNew />,
                            },
                        ],
                    },
                    {
                        path: "lending",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <Earn type={EarnType.lending} />,
                            },
                            {
                                path: "new",
                                element: <StackingNew />,
                            },
                        ],
                    },
                    {
                        path: "nodes",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <Earn type={EarnType.nodes} />,
                            },
                            {
                                path: "new",
                                element: <StackingNew />,
                            },
                        ],
                    },
                    {
                        path: "staking",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <Earn type={EarnType.staking} />,
                            },
                            {
                                path: "new",
                                element: <StackingNew />,
                            },
                        ],
                    },
                    {
                        path: "staking-defi",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <Earn type={EarnType.defi} />,
                            },
                            {
                                path: "new",
                                element: <StackingNew />,
                            },
                        ],
                    },
                    {
                        path: "loyalty",
                        element: <Fidelity />,
                    },
                    {
                        path: "wallets-v2",
                        element: <Template />,
                    },
                    {
                        path: "orders",
                        element: <Commande />,
                    },
                    {
                        path: "services",
                        element: <NewStaking />,
                    },
                    {
                        path: "governances",
                        element: <Gouvernance />,
                    },
                    {
                        path: "earn",
                        element: <Earn />,
                    },
                    {
                        path: "affiliation",
                        element: <AffiliationUser />,
                    },
                    {
                        path: "marketing",
                        element: <Marketing />,
                    },
                    {
                        path: "ecosystem",
                        element: <Evenment />,
                    },
                    // {
                    //     path: "support",
                    //     element: <Support />,
                    // },
                    {
                        path: "wallet-online",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <NewWallet />,
                            },
                            {
                                path: "swap/:origin",
                                element: <Swap />,
                            },
                            {
                                path: "deposit/:origin",
                                element: <Deposit />,
                            },
                            {
                                path: "achat/:origin",
                                element: <Achat />,
                            },
                            {
                                path: "withdraw/:origin",
                                element: <Withdraw />,
                            },
                            {
                                path: "swap-transaction",
                                element: <SwpTransfert />,
                            },
                        ],
                    },
                    {
                        path: "material",
                        children: [
                            {
                                path: "cold-wallet",
                                element: <ColdWallet />,
                            },
                            {
                                path: "visa",
                                element: <Visa />,
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export default AccountRoutes;
