import React, { useEffect, useState, useRef } from "react";

interface CounterUpProps {
    children: string;
    delay?: number;
    time?: number;
}

const CounterUp: React.FC<CounterUpProps> = ({
    children,
    delay = 10,
    time = 400,
}) => {
    const [count, setCount] = useState("0");
    const elementRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        const updateCounter = () => {
            if (!elementRef.current) return;

            const text = children;
            const nums: string[] = [];
            const endValue = parseFloat(text.replace(/,/g, ""));
            const isDecimal = text.includes(".");
            const decimalPlaces = isDecimal
                ? (text.split(".")[1] || []).length
                : 0;
            const numSteps = time / delay;

            for (let i = numSteps; i >= 1; i--) {
                let value: number | string = (endValue / numSteps) * i;
                value = isDecimal
                    ? value.toFixed(decimalPlaces)
                    : Math.floor(value);
                nums.unshift(value.toLocaleString());
            }

            let index = 0;
            const interval = setInterval(() => {
                setCount(nums[index]);
                index += 1;
                if (index >= nums.length) {
                    clearInterval(interval);
                }
            }, delay);

            return () => clearInterval(interval);
        };

        const handleScroll = () => {
            if (!elementRef.current) return;

            if (
                elementRef.current.getBoundingClientRect().top <
                window.innerHeight
            ) {
                updateCounter();
                window.removeEventListener("scroll", handleScroll);
            }
        };

        window.addEventListener("scroll", handleScroll);
        handleScroll();

        return () => window.removeEventListener("scroll", handleScroll);
    }, [children, delay, time]);

    return <span ref={elementRef}>{count}</span>;
};

export default CounterUp;
