import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import en from "../../translation/translate.en.json";
import fr from "../../translation/translate.fr.json";
import es from "../../translation/translate.es.json";
/* eslint-disable */
export enum Language {
    FR = "fr",
    EN = "en",
    ES = "es",
}
/* eslint-enable */

export interface AppLanguage {
    language: Language;
    translations: any;
}

let localLanguage = Language.EN;
if (typeof localStorage !== "undefined") {
    const storedLanguage = localStorage.getItem("UserLanguage");

    if (storedLanguage !== null) {
        localLanguage = storedLanguage as Language;
    } else {
        if (typeof navigator !== "undefined") {
            const langue = navigator.language;
            if (langue.length > 0) {
                const split_langue = langue.split("-");
                if (
                    split_langue[0] &&
                    Object.values(Language).includes(
                        split_langue[0].toLowerCase() as Language,
                    )
                ) {
                    localLanguage = split_langue[0].toLowerCase() as Language;
                    localStorage.setItem("UserLanguage", localLanguage);
                }
            }
        }
    }
}

const language = localLanguage ? localLanguage : Language.EN;

const getTranslations = (lang: Language) => {
    switch (lang) {
        case Language.EN:
            return en;
            break;
        case Language.ES:
            return es;
            break;
        default:
            return fr;
    }
};
const translations = getTranslations(language);

const initialState: AppLanguage = {
    language: language,
    translations: translations,
};

const LanguageSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        ChangeAppLanguage(
            state,
            action: PayloadAction<{ language: Language }>,
        ) {
            const language = action.payload.language;
            if (typeof localStorage !== "undefined")
                localStorage.setItem("UserLanguage", language);
            state.language = language;
            state.translations = getTranslations(language);
        },
    },
});

export const { ChangeAppLanguage } = LanguageSlice.actions;

export default LanguageSlice.reducer;
