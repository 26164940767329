import React from "react";

import translator from "../../../Components/Extra/Translation/Translate";
import Banner from "../../component/banner";
import AccessTheDashboardButton from "../buttons/access-the-dashboard";

export default function Section1() {
    const { translate } = translator();
    return (
        <>
            <Banner
                title={translate("AFFILIATION", "Title")}
                description={translate("AFFILIATION", "Description")}
                button={<AccessTheDashboardButton />}
            />
        </>
    );
}
