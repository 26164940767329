import React from "react";
import translator from "../../../Components/Extra/Translation/Translate";
import CustomButton from "../../component/Bouton/CustomButton";
import Image1 from "../../Image/Image1.jpeg";
import Image2 from "../../Image/Image2.jpeg";
import CreateAccountButton from "../buttons/create-account";

export default function ExchangeThirdSection() {
    const { translate } = translator();

    return (
        <div className="bg-[#F6F7FC]">
            <section className="flex flex-col mx-8 py-32 lg:mx-32">
                <div className="w-full flex flex-col lg:flex-row justify-between gap-24 items-center mb-16">
                    <div data-aos="fade-up" className="md:!w-1/2 mt-8  lg:mt-0">
                        <img
                            src={Image1}
                            alt="Description de l'image"
                            className="rounded-3xl"
                        />
                    </div>

                    <div
                        data-aos="fade-down"
                        className=" md:!w-1/2 ml-0 lg:-ml-40 lg:mt-0"
                    >
                        <h2 className="text-3xl font-bold mb-4 text-black  -mt-2">
                            {translate("EXCHANGE", "SECTION_4_TITLE")}
                        </h2>
                        <p className="text-base md:!text-base  mb-4 text-black ">
                            {translate("EXCHANGE", "SECTION_4_DESCRIPTION")}
                        </p>

                        <CreateAccountButton />
                    </div>
                </div>

                <div className="flex flex-col lg:flex-row justify-between items-center gap-24 md:mt-20 ">
                    <div
                        data-aos="fade-up"
                        className="md:!w-1/2 ml-0  lg:-ml-40 mt-8 lg:mt-0"
                    >
                        <h2 className="text-3xl font-bold mb-4 text-black  -mt-2">
                            {translate("EXCHANGE", "SECTION_5_TITLE")}
                        </h2>
                        <p className="text-base md:!text-base  mb-4 text-black ">
                            {translate("EXCHANGE", "SECTION_5_DESCRIPTION")}
                        </p>

                        <CustomButton
                            href="/exchange"
                            className="text-base font-semibold px-4 py-2 border-transparent text-white rounded-xl bg-clisha-600 mt-8"
                        >
                            {translate("EXCHANGE", "START_TO_EXCHANGE")}
                        </CustomButton>
                    </div>
                    <div
                        data-aos="fade-down"
                        className="md:!w-1/2 my-8 lg:mt-0"
                    >
                        <img
                            src={Image2}
                            alt="Description de l'image"
                            className=" rounded-3xl"
                        />
                    </div>
                </div>
            </section>
        </div>
    );
}
