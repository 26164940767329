import { Navigate, Outlet, useLocation } from "react-router-dom";

import useAuthentication from "../../Services/Authentication/useAuthentication";

interface IProps {
    children?: JSX.Element;
}

const PrivateRouteProvider = ({ children }: IProps) => {
    const { isAuthenticated } = useAuthentication();
    const location = useLocation();

    if (isAuthenticated) {
        return children ? children : <Outlet />;
    } else {
        return (
            <Navigate
                to={"/login"}
                state={{ from: location.pathname }}
                replace
            />
        );
    }
};

export default PrivateRouteProvider;
