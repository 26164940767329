import { useAppSelector } from "../../globalState/hook";

export default function useLanguage() {
    const { language, translations } = useAppSelector(
        (store) => store.language,
    );

    return {
        language,
        translations,
    };
}
