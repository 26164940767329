import React from "react";

import translator from "../../Components/Extra/Translation/Translate";
import Banner from "../component/banner";
import CustomButton from "../component/Bouton/CustomButton";
import EarnSecondSection from "../ui/Earn/section2";
import EarnThirdSection from "../ui/Earn/section3";
import HelmetComponent from "../component/HelmetComponent";

const Earn = () => {
    const { translate } = translator();

    return (
        <>
            <HelmetComponent
                title={translate("SEO", "Earn_title")}
                description={translate("SEO", "Earn_desc")}
                link="/earns"
                keywords={translate("SEO", "Earn_keywords")}
                imageCard="/dist/image/clishanodes-cards-1024x1024.png"
                largeTwitterCard="/dist/image/clishanodes-cards-1024x1024.png"
                addPostfixTitle={true}
                noIndex={false}
            ></HelmetComponent>
            <Banner
                title={translate("EARN", "PAGE_TITLE")}
                description={translate("EARN", "PAGE_SUBTITLE")}
                button={
                    <CustomButton
                        href="/buys#assets"
                        className=" text-base font-semibold px-4 py-2 text-white  border-transparent rounded-xl bg-[#263238] mt-8"
                    >
                        {translate("EARN", "DISCOVER_OUR_ASSETS")}
                    </CustomButton>
                }
            />
            <EarnSecondSection />
            <EarnThirdSection />
        </>
    );
};

export default Earn;
