import React from "react";
import NewCard, { NewCardProps } from "../../component/newCard/new-card";
import translator from "../../../Components/Extra/Translation/Translate";
import multiversx from "../../Image/multiversx.jpeg";
import eos from "../../Image/eos.jpeg";
import stellar from "../../Image/stellar.jpeg";
import bitcoin_cash from "../../Image/bitcoin_cash.jpeg";

export default function Section2() {
    const { translate } = translator();
    const dataNewCard: NewCardProps[] = [
        {
            image: multiversx,
            title_1: translate("NEWS", "Section2_card1_title1"),
            text_1: translate("NEWS", "Section2_card1_text1"),
            title_2: translate("NEWS", "Section2_card1_title2"),
            text_2: [
                translate("NEWS", "Section2_card1_text2_p1"),
                translate("NEWS", "Section2_card1_text2_p2"),
            ],
        },
        {
            image: eos,
            title_1: translate("NEWS", "Section2_card2_title1"),
            text_1: translate("NEWS", "Section2_card2_text1"),
            title_2: translate("NEWS", "Section2_card2_title2"),
            text_2: [
                translate("NEWS", "Section2_card2_text2_p1"),
                translate("NEWS", "Section2_card2_text2_p2"),
            ],
        },
        {
            image: stellar,
            title_1: translate("NEWS", "Section2_card3_title1"),
            text_1: translate("NEWS", "Section2_card3_text1"),
            title_2: translate("NEWS", "Section2_card3_title2"),
            text_2: [
                translate("NEWS", "Section2_card3_text2_p1"),
                translate("NEWS", "Section2_card3_text2_p2"),
            ],
        },
        {
            image: bitcoin_cash,
            title_1: translate("NEWS", "Section2_card4_title1"),
            text_1: translate("NEWS", "Section2_card4_text1"),
            title_2: translate("NEWS", "Section2_card4_title2"),
            text_2: [
                translate("NEWS", "Section2_card4_text2_p1"),
                translate("NEWS", "Section2_card4_text2_p2"),
            ],
        },
    ];
    return (
        <div>
            <section className="bg-clisha-904 py-[50px]">
                <div className="text-center">
                    <h1 className="text-clisha-500 lg:text-3xl text-2xl font-bold">
                        {translate("NEWS", "Section2_h1")}
                    </h1>
                </div>

                {dataNewCard.map((item, index) => (
                    <div
                        key={index}
                        className="lg:mx-32 mx-5 py-6 md:flex lg:justify-between lg:space-x-5 mt-[35px] bg-white !rounded-3xl shadow-2xl"
                    >
                        <NewCard
                            image={item.image}
                            title_1={item.title_1}
                            text_1={item.text_1}
                            title_2={item.title_2}
                            text_2={item.text_2}
                        />
                    </div>
                ))}
            </section>
        </div>
    );
}
