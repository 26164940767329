import { Asset } from "../../Models/Asset";
import axios from "../AxiosInstance";
import UtilsService from "../UtilsService";

export async function getAllAssets(): Promise<Asset[]> {
    return axios
        .get(`/assets`)
        .then(({ data }) => {
            return data.map((asset: Asset) => {
                return { ...asset, logo: UtilsService.getPhotoUrl(asset.logo) };
            });
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
}
