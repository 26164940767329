import React from "react";

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function useScrollTop() {
    const location = useLocation();

    useEffect(() => {
        if (location.hash !== "#assets") {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }
    }, [location.pathname, location.hash]);
}
