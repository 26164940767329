import React from "react";
import translator from "../../../Components/Extra/Translation/Translate";
import Image1 from "../../Image/Image1.jpeg";
import Image2 from "../../Image/Image2.jpeg";
import CreateAccountButton from "../buttons/create-account";

export default function Section3() {
    const { translate } = translator();
    const site_url = "";

    return (
        <div className="bg-clisha-905 py-10">
            <section className="flex flex-col mx-8 lg:!mx-32">
                <div className="w-full flex flex-col lg:flex-row justify-between gap-24 items-center mb-16">
                    <div data-aos="fade-down" className="md:w-1/2 mt-8 lg:mt-0">
                        <img
                            src={Image1}
                            alt="Description de l'image"
                            className="rounded-3xl"
                        />
                    </div>

                    <div
                        data-aos="fade-up"
                        className="md:w-1/2 ml-0 lg:ml-40 lg:mt-0"
                    >
                        <h2 className="text-3xl font-bold mb-4 text-black -mt-2">
                            {translate("HomeSection3", "Title")}
                        </h2>
                        <p className="text-base mb-4 text-black">
                            {translate("HomeSection3", "Description")}
                        </p>
                        <CreateAccountButton />
                    </div>
                </div>

                <div className="flex flex-col lg:flex-row justify-between items-center gap-24 md:mt-20 w-full">
                    <div
                        data-aos="fade-up"
                        className="md:w-1/2 ml-0 mt-8 lg:mt-0"
                    >
                        <h2 className="text-3xl font-bold mb-4 text-black -mt-2">
                            {translate("HomeSection3", "Title1")}
                        </h2>
                        <p className="text-base mb-4 text-black">
                            {translate("HomeSection3", "Description1")}
                        </p>
                        <CreateAccountButton />
                    </div>
                    <div data-aos="fade-down" className="md:w-1/2 my-8 lg:mt-0">
                        <img
                            src={Image2}
                            alt="Description de l'image"
                            className="rounded-3xl"
                        />
                    </div>
                </div>

                <div className="md:mt-32 text-base md:text-lg text-black text-center">
                    <p>{translate("HomeSection3", "Text")}</p>
                </div>
                <div className="mx-auto mt-10">
                    <img
                        decoding="async"
                        width="800"
                        height="60"
                        src={`${site_url}/images/brands-1024x77.png`}
                        className="attachment-large size-large wp-image-51"
                        alt=""
                        srcSet={`${site_url}/images/brands-1024x77.png 1024w, ${site_url}/images/brands-300x22.png 300w, ${site_url}/images/brands-768x58.png 768w, ${site_url}/images/brands.png 1148w`}
                        sizes="(max-width: 800px) 100vw, 800px"
                    />
                </div>
            </section>
        </div>
    );
}
