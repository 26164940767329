import React from "react";

import Section1 from "../ui/FAQ/Section1";
import Section2 from "../ui/FAQ/Section2";
import HelmetComponent from "../component/HelmetComponent";
import translator from "../../Components/Extra/Translation/Translate";

export default function Faq() {
    const { translate } = translator();
    return (
        <>
            <HelmetComponent
                title={translate("SEO", "Faq_title")}
                description={translate("SEO", "Faq_desc")}
                link="/faq"
                keywords={translate("SEO", "Faq_keywords")}
                imageCard="/dist/image/clishanodes-cards-1024x1024.png"
                largeTwitterCard="/dist/image/clishanodes-cards-1024x1024.png"
                addPostfixTitle={true}
                noIndex={false}
            ></HelmetComponent>
            <Section1 />
            <Section2 />
        </>
    );
}
