import React from "react";

import Section1 from "../ui/News/section1";
import Section2 from "../ui/News/section2";
import HelmetComponent from "../component/HelmetComponent";
import translator from "../../Components/Extra/Translation/Translate";

export default function News() {
    const { translate } = translator();
    return (
        <>
            <HelmetComponent
                title={translate("SEO", "Actuality")}
                description={translate("SEO", "News_desc")}
                link="/news"
                keywords={translate("SEO", "News_keywords")}
                imageCard="/dist/image/clishanodes-cards-1024x1024.png"
                largeTwitterCard="/dist/image/clishanodes-cards-1024x1024.png"
                addPostfixTitle={true}
                noIndex={false}
            ></HelmetComponent>

            <Section1 />
            <Section2 />
        </>
    );
}
