import React from "react";

import translator from "../../../Components/Extra/Translation/Translate";
import ContactUs from "../buttons/contact-us";

export default function Section3() {
    const { translate } = translator();
    return (
        <>
            <div className="bg-clisha-600 py-32">
                <section className="flex justify-center items-center mx-8  lg:mx-32">
                    <div className="max-w-screen-xl mx-auto w-full text-center">
                        <div className="" data-aos="zoom-in">
                            <h2 className="text-white font-bold md:font-bold  md:text-3xl text-3xl pb-5">
                                {translate("COMPANY", "section3_div3_h2")}
                            </h2>

                            <p className="text-white md:text-xl text-xl">
                                {translate("COMPANY", "section3_div3_p")}
                            </p>
                            <div className="flex flex-wrap md:gap-0 leading-normal gap-3 justify-center items-center space-x-2">
                                <ContactUs styleBtn="text-base  px-4 py-2 text-white !border-transparent rounded-xl bg-clisha-500 hover:bg-clisha-600  mt-8" />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}
