import React from "react";

import translator from "../../Components/Extra/Translation/Translate";
import Banner from "../component/banner";

export default function Condition() {
    const { translate } = translator();

    return (
        <>
            <Banner title={translate("CONDITION", "h1")} description="" />

            <section className="flex justify-center items-center   py-16 text-black bg-white">
                <div className="max-w-screen-xl mx-auto w-full">
                    <div className="max-w-screen-xl mx-auto w-full text-center py-8 text-slate-888 ">
                        <div>
                            <div className="bg-[#e5e7eb] text-left py-10 md:px-[140px] px-[20px] rounded-tl-[120px] space-y-4 ">
                                <p>
                                    {translate("CONDITION", "p1_1")}{" "}
                                    <span className="font-bold">
                                        Clishanodes
                                    </span>
                                    {translate("CONDITION", "p1_2")}{" "}
                                    <span className="font-bold">
                                        Clishanodes
                                    </span>
                                    .
                                </p>
                                <p>{translate("CONDITION", "p2")}</p>
                                <p> {translate("CONDITION", "p3")}</p>
                                <p> {translate("CONDITION", "p4")}</p>
                                <p> {translate("CONDITION", "p5")}</p>

                                <div className="flex-wrap  md:flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A1")}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "t1")}
                                    </p>
                                </div>
                                <div className="space-y-4">
                                    <p>{translate("CONDITION", "c1_1")}</p>
                                    <p>{translate("CONDITION", "c1_2")}</p>
                                    <p>{translate("CONDITION", "c1_3")}</p>
                                </div>

                                <div className="flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A2")}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "t2")}
                                    </p>
                                </div>
                                <div className="space-y-4">
                                    <p> {translate("CONDITION", "c2_1")} </p>
                                    <p>{translate("CONDITION", "c2_2")} </p>
                                    <p> {translate("CONDITION", "c2_3")}</p>
                                    <p>{translate("CONDITION", "c2_4")} </p>
                                </div>

                                <div className="flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A3")}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "t3")}
                                    </p>
                                </div>
                                <div className="space-y-4">
                                    <p>{translate("CONDITION", "c3_1")}</p>
                                    <p>{translate("CONDITION", "c3_2")}</p>
                                </div>

                                <div className="flex-wrap md:flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A4")}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "t4")}
                                    </p>
                                </div>
                                <div className="space-y-4">
                                    <p> {translate("CONDITION", "c4_1")}</p>
                                    <p> {translate("CONDITION", "c4_2")}</p>
                                </div>

                                <div className="flex-wrap md:flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A5")}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "t5")}
                                    </p>
                                </div>
                                <div>
                                    <p>{translate("CONDITION", "c5")}</p>
                                </div>

                                <div className="flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A6")}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "t6")}
                                    </p>
                                </div>
                                <div>
                                    <p>{translate("CONDITION", "c6")}</p>
                                </div>

                                <div className="flex-wrap md:flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A7")}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "t7")}
                                    </p>
                                </div>
                                <div className="space-y-4">
                                    <p>{translate("CONDITION", "c7_1")}</p>
                                    <p>{translate("CONDITION", "c7_2")}</p>
                                    <p>{translate("CONDITION", "c7_3")}</p>
                                </div>

                                <div className="flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A8")}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "t8")}
                                    </p>
                                </div>
                                <div className="space-y-4">
                                    <p>{translate("CONDITION", "c8_1")} </p>

                                    <p>{translate("CONDITION", "c8_2")} </p>

                                    <p>{translate("CONDITION", "c8_3")} </p>

                                    <p>{translate("CONDITION", "c8_4")} </p>
                                </div>

                                <div className="flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A9")}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "t9")}
                                    </p>
                                </div>
                                <div className="space-y-4">
                                    <p>{translate("CONDITION", "c9_1")}</p>
                                    <p> {translate("CONDITION", "c9_2")} </p>

                                    <p> {translate("CONDITION", "c9_3")}</p>
                                </div>

                                <div className="flex-wrap md:flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A10")}{" "}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "t10")}
                                    </p>
                                </div>

                                <div className="space-y-4">
                                    <p>{translate("CONDITION", "c10_1")} </p>
                                    <p>{translate("CONDITION", "c10_2")} </p>

                                    <p>{translate("CONDITION", "c10_3")} </p>

                                    <p>{translate("CONDITION", "c10_4")} </p>

                                    <p>{translate("CONDITION", "c10_5")}</p>
                                </div>

                                <div className="flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A11")}{" "}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "t11")}
                                    </p>
                                </div>

                                <div className="space-y-4">
                                    <p>{translate("CONDITION", "c11_1")} </p>
                                    <p>{translate("CONDITION", "c11_2")} </p>
                                    <p>{translate("CONDITION", "c11_3")} </p>
                                    <p>{translate("CONDITION", "c11_4")}</p>
                                </div>

                                <div className="flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A12")}{" "}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "T12")}{" "}
                                    </p>
                                </div>

                                <div className="space-y-4">
                                    <p>{translate("CONDITION", "C12")}</p>

                                    <p>{translate("CONDITION", "C12_a")}</p>

                                    <p>{translate("CONDITION", "C12_b")}</p>
                                </div>

                                <div className="flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A13")}{" "}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "T13")}{" "}
                                    </p>
                                </div>
                                <div>
                                    <p>{translate("CONDITION", "C13")}</p>
                                </div>

                                <div className="flex-wrap md:flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A14")}{" "}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "T14")}
                                    </p>
                                </div>

                                <div className="space-y-4">
                                    <p>{translate("CONDITION", "C14_a")} </p>
                                    <p>{translate("CONDITION", "C14_b")}</p>
                                </div>

                                <div className="flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A15")}{" "}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "T15")}
                                    </p>
                                </div>

                                <div className="space-y-4">
                                    <p>{translate("CONDITION", "C15_a")}</p>
                                    <p>{translate("CONDITION", "C15_b")}</p>
                                    <p>{translate("CONDITION", "C15_c")}</p>
                                </div>

                                <div className="flex-wrap md:flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A16")}{" "}
                                    </h5>
                                    <p className="ml-1">
                                        {" "}
                                        {translate("CONDITION", "T16")}
                                    </p>
                                </div>

                                <div className="space-y-4">
                                    <p>{translate("CONDITION", "C16_a")}</p>
                                    <p>{translate("CONDITION", "C16_b")}</p>
                                    <p>{translate("CONDITION", "C16_c")}</p>
                                    <p>{translate("CONDITION", "C16_d")}</p>
                                    <p>{translate("CONDITION", "C16_e")}</p>
                                </div>
                                <div>
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "T16_a")}
                                    </h5>

                                    <div className="space-y-4">
                                        <p>
                                            {translate("CONDITION", "C16_f")}{" "}
                                        </p>

                                        <p>
                                            {translate("CONDITION", "C16_g")}{" "}
                                        </p>

                                        <p>
                                            {translate("CONDITION", "C16_h")}{" "}
                                        </p>

                                        <p>
                                            {translate("CONDITION", "C16_i")}{" "}
                                        </p>
                                    </div>

                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "T16_b")}
                                    </h5>

                                    <p>{translate("CONDITION", "C16_j")}</p>
                                </div>

                                <div className="flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A17")}
                                    </h5>

                                    <p className="ml-1">
                                        {translate("CONDITION", "T17")}
                                    </p>
                                </div>
                                <div>
                                    <p>{translate("CONDITION", "C17_a")}</p>
                                </div>

                                <div className="flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A18")}{" "}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "T18")}
                                    </p>
                                </div>
                                <div>
                                    <p>{translate("CONDITION", "C18")}</p>
                                </div>
                                <div className="flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A19")}{" "}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "T19")}
                                    </p>
                                </div>

                                <div className="space-y-4">
                                    <p>{translate("CONDITION", "C19_a")}</p>
                                    <p>{translate("CONDITION", "C19_b")}</p>
                                    <p>{translate("CONDITION", "C19_c")}</p>
                                </div>
                                <div className="flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A20")}{" "}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "T20")}
                                    </p>
                                    <br />
                                    <br />
                                </div>
                                <div className="space-y-4">
                                    <p>{translate("CONDITION", "C20_a")}</p>
                                    <p>{translate("CONDITION", "C20_b")}</p>
                                </div>
                                <div className="flex-wrap md:flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A21")}
                                    </h5>
                                    <p className="ml-1">
                                        {translate("CONDITION", "T21")}
                                    </p>
                                </div>
                                <div className="space-y-4">
                                    <p>{translate("CONDITION", "C21_a")}</p>
                                    <p>{translate("CONDITION", "C21_b")}</p>
                                </div>
                                <div className="flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A22")}
                                    </h5>
                                    <p className="ml-1">
                                        {" "}
                                        {translate("CONDITION", "T22")}
                                    </p>
                                </div>
                                <div>
                                    <p>
                                        {translate("CONDITION", "C22_a")}{" "}
                                        <span className="font-bold">
                                            {" "}
                                            Clishanodes
                                        </span>
                                        .{" "}
                                    </p>
                                </div>
                                <div className="flex items-center mt-[30px]">
                                    <h5 className="text-[14px] py-[13px]">
                                        {translate("CONDITION", "A23")}
                                    </h5>
                                    <p className="ml-1">
                                        {" "}
                                        {translate("CONDITION", "T23")}
                                    </p>
                                </div>
                                <div>
                                    <p>{translate("CONDITION", "C23")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
