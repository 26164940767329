import React from "react";
import FeatureCard from "../../component/FeatureCard";
import { faCode, faTasks, faCogs } from "@fortawesome/free-solid-svg-icons";
import translator from "../../../Components/Extra/Translation/Translate";

export default function Section3() {
    const { translate } = translator();
    return (
        <div>
            <section className="bg-[#F7F7F7]  px-8 pt-2 pb-[90px] lg:!px-32 ">
                <div className="grid grid-cols-1 md:!grid-cols-3 gap-8 max-md:max-w-md mx-auto mt-[100px]">
                    <div data-aos="flip-right">
                        <FeatureCard
                            icon={faCode}
                            title={translate(
                                "WHITE_LABEL",
                                "section3_featureCard1_title",
                            )}
                            description={translate(
                                "WHITE_LABEL",
                                "section3_featureCard1_description",
                            )}
                            classname="!text-clisha-903 !border-2  !rounded-lg"
                        />
                    </div>
                    <div data-aos="flip-right">
                        <FeatureCard
                            icon={faTasks}
                            title={translate(
                                "WHITE_LABEL",
                                "section3_featureCard2_title",
                            )}
                            description={translate(
                                "WHITE_LABEL",
                                "section3_featureCard2_description",
                            )}
                            classname="bg-clisha-904"
                        />
                    </div>
                    <div data-aos="flip-right">
                        <FeatureCard
                            icon={faCogs}
                            title={translate(
                                "WHITE_LABEL",
                                "section3_featureCard3_title",
                            )}
                            description={translate(
                                "WHITE_LABEL",
                                "section3_featureCard3_description",
                            )}
                            classname="bg-clisha-904"
                        />
                    </div>
                </div>
            </section>
        </div>
    );
}
