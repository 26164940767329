import React from "react";

import translator from "../../../Components/Extra/Translation/Translate";
import CustomButton from "../../component/Bouton/CustomButton";

export type StartNowProps = {
    styleBtn: string;
};

export default function StartNow({ styleBtn }: StartNowProps) {
    const { translate } = translator();
    return (
        <CustomButton href="" className={styleBtn}>
            {translate("START_NOW", "start_now")}
        </CustomButton>
    );
}
