import React from "react";
import { faSortDesc } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink, useLocation } from "react-router-dom";

export type MenuItemProps = {
    path: string;
    label: string;
    children?: MenuItemProps[];
};

export default function MenuItem({ path, label, children }: MenuItemProps) {
    const location = useLocation();
    const hasChild = children && children.length > 0;

    const isActive =
        location.pathname === path ||
        (hasChild &&
            children.some((child) => location.pathname === child.path));

    return (
        <div className="group w-full relative">
            <NavLink
                to={path}
                className={`px-4 py-2 space-x-2 flex justify-between items-center ${
                    isActive
                        ? "!text-clisha-500 !underline !underline-offset-4 !decoration-2"
                        : "hover:!text-clisha-500"
                }`}
            >
                <span>{label}</span>
                {hasChild && (
                    <div
                        className={`group-hover:rotate-180 -mt-1 group-hover:mt-2 ${
                            isActive ? "text-clisha-500" : ""
                        }`}
                    >
                        <FontAwesomeIcon icon={faSortDesc} />
                    </div>
                )}
            </NavLink>

            {hasChild && (
                <ul className="hidden group-hover:block md:!absolute bg-clisha-600 p-5 rounded-lg space-y-1 w-max">
                    {children.map((child, index) => (
                        <li key={`child-item-${index}`}>
                            <NavLink
                                to={child.path}
                                className={({ isActive }) =>
                                    `hover:text-clisha-500 px-4 py-2 space-x-2 flex justify-between ${
                                        isActive ? "text-clisha-500" : ""
                                    }`
                                }
                            >
                                {child.label}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}
