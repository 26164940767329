import React from "react";

import { useEffect, useState } from "react";
import {
    EarnCryptoPlans,
    getEarnPlans,
} from "../Services/Plans/earn-plans-services";
import alert_message from "../Services/Alert/AlertMessage";

export default function useEarnPlans() {
    const [plans, setPlans] = useState<EarnCryptoPlans[]>([]);

    function setDefaulPlan(
        earnPlans: EarnCryptoPlans[],
        symbol: string,
        planId: number,
    ) {
        const planIndex = earnPlans.findIndex((plan) => plan.symbol === symbol);

        if (planIndex < 0) return;

        const plan = earnPlans[planIndex].plans.find(
            (p) => p.planId === planId,
        );

        if (!plan) return;

        earnPlans[planIndex].defaultPlanId = planId;
        earnPlans[planIndex].defaultRoi = plan.roi;

        setPlans([...earnPlans]);
    }

    useEffect(() => {
        getEarnPlans()
            .then((data) => {
                setPlans(data);
            })
            .catch((err) => {
                alert_message.error(err);
            });
    }, []);

    return {
        plans,
        setDefaulPlan,
    };
}
