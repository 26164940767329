import useLanguage from "../../../Services/Hooks/useLanguage";
// type MyJsonType = Record<string, any>;

export default function translator() {
    const { translations } = useLanguage();

    const translate = (domain: string, text: string, param?: any) => {
        let dom = domain;
        let txt = text;

        if (domain === ".") {
            const splited = text.split(".");
            if (splited.length === 2) {
                dom = splited[0];
                txt = splited[1];
            }
        }

        let text_translated =
            translations[dom] && translations[dom][txt]
                ? translations[dom][txt]
                : text;

        if (typeof param === "object") {
            const keys = Object.keys(param);

            for (const key of keys) {
                text_translated = setParameter(
                    text_translated,
                    key,
                    param[key],
                );
            }
        }

        return text_translated;
    };

    const setParameter = (text: string, key: string, value: any) => {
        return text.replace(new RegExp(`{{${key}}}`, "g"), value);
    };

    return {
        translate,
    };
}
