import React from "react";
import { NavLink } from "react-router-dom";
import translator from "../../../Components/Extra/Translation/Translate";

export default function AffiliationFiveSection() {
    const { translate } = translator();
    return (
        <>
            <div className="bg-clisha-905 py-32">
                <section className="flex text-clisha-903 justify-center items-center mx-8  lg:mx-32">
                    <div className="max-w-screen-xl mx-auto w-full text-center">
                        <div className="" data-aos="zoom-in">
                            <p className=" md:text-xl text-xl">
                                {translate("AFFILIATION", "SECTION_5_P1")}{" "}
                                <span>
                                    <NavLink
                                        to="/faq"
                                        className="text-clisha-500"
                                    >
                                        {" "}
                                        {translate(
                                            "AFFILIATION",
                                            "SECTION_5_LINK",
                                        )}
                                    </NavLink>
                                </span>
                            </p>
                            <p className=" md:text-xl text-xl">
                                {translate("AFFILIATION", "SECTION_5_P2")}
                            </p>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}
