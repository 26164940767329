import React from "react";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

export default function AosAnimation() {
    useEffect(() => {
        AOS.init({
            duration: 1200,
            once: false,
            mirror: false,
        });
    }, []);
}
