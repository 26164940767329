import React from "react";
import { NavLink } from "react-router-dom";

interface CustomCardsProps {
    title: string;
    subTitle: string;
    content: string;
    links?: string;
    contentLinks?: string;
    image?: string;
    classname?: string;
}

const CardHome: React.FC<CustomCardsProps> = ({
    title,
    subTitle,
    content,
    links,
    contentLinks,
    image,
    classname,
}) => {
    return (
        <div
            className={`text-clisha-903 !border-2 !shadow-xl p-[45px] !rounded-lg ${classname} `}
        >
            <div>
                <h1 className="font-bold  text-2xl">{title}</h1>
                <h2 className="font-bold  text-xl mt-2">{subTitle}</h2>
                <p className="text-base mt-2">{content}</p>
                {links && contentLinks && (
                    <NavLink
                        to={links}
                        className="mt-[30px] text-lg text-clisha-500 underline"
                    >
                        {contentLinks}
                    </NavLink>
                )}
            </div>

            {image && (
                <img
                    src={image}
                    className="pt-[50px] w-full rounded-lg"
                    alt="Card Image"
                />
            )}
        </div>
    );
};

export default CardHome;
