import React from "react";

import CreateAccountButton from "../buttons/create-account";
import Image1 from "../../Image/Image1.jpeg";
import Image2 from "../../Image/Image2.jpeg";
import translator from "../../../Components/Extra/Translation/Translate";

export default function Section2() {
    const { translate } = translator();
    return (
        <div className="bg-[#F6F7FC] lg:!py-32 py-8">
            <section className="flex flex-col mx-8  lg:mx-32">
                <div className="w-full flex flex-col lg:flex-row justify-between gap-24 items-center mb-16">
                    <div
                        data-aos="fade-down"
                        className="md:!w-1/2 mt-8  lg:mt-0"
                    >
                        <img
                            src={Image1}
                            alt="Description de l'image"
                            className="rounded-3xl"
                        />
                    </div>

                    <div
                        data-aos="fade-up"
                        className=" md:!w-1/2 ml-0 lg:-ml-40 lg:mt-0"
                    >
                        <h2 className="text-3xl font-bold mb-4 text-black  -mt-2">
                            {translate("COMPANY", "section2_div1_h2")}
                        </h2>
                        <p className="text-base md:!text-base  mb-4 text-black ">
                            {translate("COMPANY", "section2_div1_p")}
                        </p>

                        <CreateAccountButton />
                    </div>
                </div>

                <div className="flex flex-col lg:flex-row justify-between items-center gap-24 md:mt-20 ">
                    <div
                        data-aos="fade-down"
                        className="md:!w-1/2 ml-0  lg:-ml-40 mt-8 lg:mt-0"
                    >
                        <h2 className="text-3xl font-bold mb-4 text-black  -mt-2">
                            {translate("COMPANY", "section2_div2_h2")}
                        </h2>
                        <p className="text-base md:!text-base  mb-4 text-black ">
                            {translate("COMPANY", "section2_div2_p")}
                        </p>

                        <CreateAccountButton />
                    </div>
                    <div data-aos="fade-up" className="md:!w-1/2 lg:mt-0">
                        <img
                            src={Image2}
                            alt="Description de l'image"
                            className="rounded-3xl"
                        />
                    </div>
                </div>
            </section>
        </div>
    );
}
