import React from "react";
import { Link } from "react-router-dom";

export type LinkFooter = {
    path: string;
    className: string;
    label: string;
};
export type MenuItemFooterProps = {
    className: string;
    label: string;
    items: LinkFooter[];
};

export default function MenuItemFooter({
    className,
    label,
    items,
}: MenuItemFooterProps) {
    return (
        <>
            <h5 className={className}>{label}</h5>
            {items.map((item, index) => (
                <div key={`item-${index}`}>
                    <Link to={item.path} className={item.className}>
                        {item.label}
                    </Link>
                </div>
            ))}
        </>
    );
}
