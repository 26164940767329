import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./header";
import AosAnimation from "../../hooks/useAosAnimation";

export default function BaseAuth() {
    AosAnimation();
    return (
        <>
            <Header />
            <Outlet />
        </>
    );
}
