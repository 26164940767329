import NotificationModel from "../../Models/NotificationModel";
import axios from "../AxiosInstance";
import UtilsService from "../UtilsService";

const mapNotification = (item: any): NotificationModel => {
    const rank = new NotificationModel(
        item.title,
        item.description,
        item.is_seen,
    );

    rank.created_at = item.created_at;
    rank.updated_at = item.updated_at;
    rank.id = item.id;

    return rank;
};

const getAll = async (): Promise<Array<NotificationModel>> => {
    return axios
        .get(`/notification`)
        .then(({ data }) => {
            const res: NotificationModel[] = data.map((item: any) =>
                mapNotification(item),
            );

            return res;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getById = async (id: number): Promise<NotificationModel> => {
    return axios
        .get(`/notification/${id}`)
        .then(({ data }) => {
            const res: NotificationModel = mapNotification(data);

            return res;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const isSeen = async (id: number) => {
    try {
        const result = await axios.put(`/notification/${id}`);

        return Promise.resolve(result.data.message);
    } catch (err: any) {
        const error =
            err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : err.message;

        return Promise.reject(error);
    }
};

const NotificationService = {
    getAll,
    getById,
    isSeen,
};

export default NotificationService;
