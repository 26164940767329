import React from "react";
import CardHome from "../../component/cards/CustomCard";

import translator from "../../../Components/Extra/Translation/Translate";

export default function AffiliationFourSection() {
    const { translate } = translator();
    return (
        <div>
            <section className="bg-clisha-904 py-32 space-y-10">
                <div className="text-center">
                    <h1 className="text-clisha-500 lg:text-3xl text-2xl font-bold">
                        {translate("AFFILIATION", "SECTION_4_TITLE")}
                    </h1>
                </div>

                <div className="lg:mx-32 mx-8 md:flex lg:justify-between lg:space-x-5 ">
                    <div
                        className="md:w-[50%] flex flex-col justify-between"
                        data-aos="zoom-in"
                    >
                        <CardHome
                            title={translate("AFFILIATION", "CARD_3_TITLE")}
                            subTitle={translate(
                                "AFFILIATION",
                                "CARD_3_SUBTITLE",
                            )}
                            content={translate("AFFILIATION", "CARD_3_CONTENT")}
                            classname="flex-grow"
                        />
                    </div>

                    <div className="md:w-[50%]" data-aos="zoom-in">
                        <CardHome
                            title={translate("AFFILIATION", "CARD_4_TITLE")}
                            subTitle={translate(
                                "AFFILIATION",
                                "CARD_4_SUBTITLE",
                            )}
                            content={translate("AFFILIATION", "CARD_4_CONTENT")}
                            classname="flex-grow "
                        />
                    </div>
                </div>
            </section>
        </div>
    );
}
