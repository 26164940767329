import React from "react";

import { useEffect, useMemo } from "react";
import {
    MantineReactTable,
    useMantineReactTable,
    type MRT_ColumnDef,
} from "mantine-react-table";
import { MRT_Localization_FR } from "mantine-react-table/locales/fr";
import { MRT_Localization_EN } from "mantine-react-table/locales/en";
import { EarnCryptoPlans } from "../../../Services/Plans/earn-plans-services";
import useEarnPlans from "../../../hooks/useEarnPlans";
import UtilsService from "../../../Services/UtilsService";
import translator from "../../../Components/Extra/Translation/Translate";
import useLanguage from "../../../Services/Hooks/useLanguage";
import useIsMobile from "../../../hooks/useIsMobile";
import { Language } from "../../../globalState/reducers/Language.reducer";

const EarnThirdSection = () => {
    const earnPlan = useEarnPlans();
    const { language } = useLanguage();
    const { translate } = translator();
    const isMobile = useIsMobile();

    const columns = useMemo<MRT_ColumnDef<EarnCryptoPlans>[]>(
        () => [
            {
                accessorFn: (row) => `${row.name}`,
                id: "name",
                header: translate("GENERAL", "ASSET"),
                size: 150,
                filterVariant: "autocomplete",
                Cell: ({ renderedCellValue, row }) => (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "16px",
                        }}
                    >
                        <img
                            alt="avatar"
                            width={40}
                            src={UtilsService.getPhotoUrl(row.original.logo)}
                            style={{ borderRadius: "50%" }}
                        />
                        <div className="grid grid-cols-1 gap-0">
                            <span className="font-bold">
                                {renderedCellValue}
                            </span>
                            <span className="text-slate-500">
                                {row.original.symbol}
                            </span>
                        </div>
                    </div>
                ),
            },
            {
                accessorKey: "defaultRoi",
                enableClickToCopy: true,
                header: translate("Earning", "Taux"),
                size: 100,
                Cell: ({ row }) => (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "16px",
                        }}
                    >
                        <span>{row.original.defaultRoi}%</span>
                    </div>
                ),
            },
            {
                accessorKey: "last24Hrs",
                header: translate("Earning", "Prix_Evo"),
                size: 150,
                filterVariant: "range-slider",
                mantineFilterRangeSliderProps: {
                    color: "indigo",
                    label: (value) =>
                        value?.toLocaleString?.("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        }),
                },
                Cell: ({ cell, row }) => (
                    <div
                        style={{
                            backgroundColor:
                                cell.getValue<number>() < 0
                                    ? "#ff0000" // red
                                    : cell.getValue<number>() === 0
                                    ? "#ffff00" // yellow
                                    : "#00ff00", // green
                            borderRadius: "4px",
                            color: "#fff",
                            maxWidth: "9ch",
                            padding: "4px",
                        }}
                    >
                        {row.original.last24Hrs >= 0 ? "+" : "-"}
                        {` ${row.original.last24Hrs}%`}
                    </div>
                ),
            },
            {
                accessorKey: "plans",
                header: translate("Earning", "Duration"),
                size: 350,
                enableSorting: false,
                enableColumnActions: false,
                Cell: ({ row }) => (
                    <div className="space-x-2">
                        {row.original.plans.map((plan, index) => {
                            const selected =
                                plan.planId == row.original.defaultPlanId;
                            return (
                                <button
                                    key={index}
                                    type="button"
                                    onClick={() =>
                                        earnPlan.setDefaulPlan(
                                            earnPlan.plans,
                                            row.original.symbol,
                                            plan.planId,
                                        )
                                    }
                                    className={
                                        selected
                                            ? "btn bg-white text-black-400 border border-[#FC9828] !px-5 relative h-[45px] bt-duration"
                                            : "btn bg-white text-black-400 border !px-5 h-[45px] bt-duration"
                                    }
                                >
                                    {plan.days === 0 && (
                                        <span>{plan.name}</span>
                                    )}
                                    {plan.days !== 0 && (
                                        <span>
                                            {plan.days}{" "}
                                            {translate("Packs_Booster", "Days")}
                                        </span>
                                    )}

                                    {selected && (
                                        <div className="corner-ribbon">
                                            <span className="cr-inner">
                                                <span className="cr-text"></span>
                                            </span>
                                        </div>
                                    )}
                                </button>
                            );
                        })}
                    </div>
                ),
            },
        ],
        [language, earnPlan.plans],
    );

    const table = useMantineReactTable({
        columns,
        data: earnPlan.plans,
        enableFullScreenToggle: false,
        enableColumnFilterModes: true,
        enableColumnOrdering: true,
        enableFacetedValues: true,
        enableGrouping: true,
        enableColumnResizing: false,
        enablePinning: true,
        initialState: {
            showColumnFilters: false,
            showGlobalFilter: false,
        },
        localization:
            language === Language.FR
                ? MRT_Localization_FR
                : MRT_Localization_EN,
        paginationDisplayMode: "pages",
        positionToolbarAlertBanner: "bottom",
        mantinePaginationProps: {
            radius: "xl",
            size: "lg",
            sx: {
                ".mantine-Pagination-item": {
                    border: "2px solid #263238", // Customize border color
                    borderRadius: "8px", // Customize border radius
                    padding: "4px", // Add padding if needed
                    "&.mantine-Pagination-item-active": {
                        borderColor: "#007BFF",
                    },
                },
                ".mantine-Pagination-control": {
                    border: "2px solid #a8adaf",
                    borderRadius: "8px",
                    padding: "4px",
                },
            },
            lang: "fr-FR",
        },
        mantineSearchTextInputProps: {
            placeholder: "Recherche",
            sx: {
                border: "2px solid #99a0a7",
                borderRadius: "8px",
                width: "100%",
                "&:focus": {
                    borderColor: "transparent",
                },
            },
        },
    });

    useEffect(() => {
        table.setState({
            ...table.getState(),
            columnVisibility: {
                defaultRoi: !isMobile,
                plans: !isMobile,
            },
        });
    }, [isMobile, table]);

    return (
        <>
            <div className="bg-clisha-600 text-center py-32 px-8">
                <div data-aos="zoom-in-up">
                    <h2 className="text-white md:font-bold  md:text-5xl text-4xl mb-10">
                        {translate("EARN", "SECTION_3_TITLE")}
                    </h2>
                    <p className="text-base text-white md:!px-36">
                        {translate("EARN", "SECTION_3_DESCRIPTION")}
                    </p>
                </div>
            </div>
            <div className="bg-white py-32">
                <section className="flex justify-center items-center mx-2 lg:!mx-32">
                    <div
                        data-aos="zoom-in"
                        className="min-w-screen-xl mx-auto w-full"
                    >
                        <MantineReactTable table={table} />
                    </div>
                </section>
            </div>
        </>
    );
};

export default EarnThirdSection;
