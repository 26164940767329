import React from "react";

import translator from "../../Components/Extra/Translation/Translate";
import Banner from "../component/banner";
import ExchangeNowButton from "../ui/buttons/exchange-now";
import ExchangeSecondSection from "../ui/Echange/Section2";
import ExchangeThirdSection from "../ui/Echange/Section3";
import ExchangeFourthSection from "../ui/Echange/Section4";
import HelmetComponent from "../component/HelmetComponent";

export default function Echange() {
    const { translate } = translator();

    return (
        <>
            <HelmetComponent
                title={translate("SEO", "Echange_title")}
                description={translate("SEO", "Echange_desc")}
                link="/exchanges"
                keywords={translate("SEO", "Echange_keywords")}
                imageCard="/dist/image/clishanodes-cards-1024x1024.png"
                largeTwitterCard="/dist/image/clishanodes-cards-1024x1024.png"
                addPostfixTitle={true}
                noIndex={false}
            ></HelmetComponent>
            <Banner
                title={translate("EXCHANGE", "PAGE_TITLE")}
                description={translate("EXCHANGE", "PAGE_SUBTITLE")}
                button={<ExchangeNowButton />}
            />
            <ExchangeSecondSection />
            <ExchangeFourthSection />
            <ExchangeThirdSection />
        </>
    );
}
