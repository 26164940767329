import React from "react";

import Section1 from "../ui/Conseiller/Section1";
import Section2 from "../ui/Conseiller/Section2";
import Section4 from "../ui/Conseiller/Section4";
import Section3 from "../ui/Entreprise/section3";
import HelmetComponent from "../component/HelmetComponent";
import translator from "../../Components/Extra/Translation/Translate";

export default function Conseillers() {
    const { translate } = translator();
    return (
        <>
            <HelmetComponent
                title={translate("SEO", "Financial_advisors_title")}
                description={translate("SEO", "Financial_advisors_desc")}
                link="/conseillers-financiers"
                keywords={translate("SEO", "Financial_advisors_keywords")}
                imageCard="/dist/image/clishanodes-cards-1024x1024.png"
                largeTwitterCard="/dist/image/clishanodes-cards-1024x1024.png"
                addPostfixTitle={true}
                noIndex={false}
            ></HelmetComponent>
            <Section1 />
            <Section2 />
            <Section3 />
            <Section4 />
        </>
    );
}
