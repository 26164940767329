import React from "react";
import Section3 from "../ui/Home/Section3";
import Section1 from "../ui/Home/section1";
import Section2 from "../ui/Home/section2";
import Section4 from "../ui/Home/section4";
import HelmetComponent from "../component/HelmetComponent";
import translator from "../../Components/Extra/Translation/Translate";

export default function Home() {
    const { translate } = translator();
    return (
        <>
            <HelmetComponent
                title={translate("SEO", "Home_title")}
                description={translate("SEO", "Home_desc")}
                link="/"
                keywords={translate("SEO", "Home_keywords")}
                imageCard="/dist/image/clishanodes-cards-1024x1024.png"
                largeTwitterCard="/dist/image/clishanodes-cards-1024x1024.png"
                addPostfixTitle={true}
                noIndex={false}
            ></HelmetComponent>
            <Section1 />
            <Section2 />
            <Section3 />
            <Section4 />
        </>
    );
}
