import React from "react";

import translator from "../../../Components/Extra/Translation/Translate";
import FeatureCard from "../../component/FeatureCard";
import {
    faArrowRightArrowLeft,
    faHandHoldingDollar,
    faWaveSquare,
} from "@fortawesome/free-solid-svg-icons";

export default function ExchangeSecondSection() {
    const { translate } = translator();

    return (
        <section className="bg-[#F7F7F7]  ">
            <div className="flex justify-center items-center py-32 mx-8 lg:!mx-32">
                <div className="min-w-screen-xl mx-auto w-full space-y-8">
                    <h1 className="text-clisha-600 md:font-bold md:text-5xl text-4xl text-center ">
                        {translate("EXCHANGE", "SECTION_2_TITLE")}
                    </h1>
                    <div className="grid grid-cols-1 md:!grid-cols-3 gap-8 max-md:max-w-md mx-auto">
                        <div data-aos="flip-up">
                            <FeatureCard
                                icon={faArrowRightArrowLeft}
                                title={translate("EXCHANGE", "CARD_1_TITLE")}
                                description={translate(
                                    "EXCHANGE",
                                    "CARD_1_DESCRIPTION",
                                )}
                                classname="bg-clisha-904"
                            />
                        </div>

                        <div data-aos="flip-up">
                            <FeatureCard
                                icon={faHandHoldingDollar}
                                title={translate("EXCHANGE", "CARD_2_TITLE")}
                                description={translate(
                                    "EXCHANGE",
                                    "CARD_2_DESCRIPTION",
                                )}
                                classname="bg-clisha-904"
                            />
                        </div>
                        <div data-aos="flip-up">
                            <FeatureCard
                                icon={faWaveSquare}
                                title={translate("EXCHANGE", "CARD_3_TITLE")}
                                description={translate(
                                    "EXCHANGE",
                                    "CARD_3_DESCRIPTION",
                                )}
                                classname="bg-clisha-904"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
