import React from "react";

import { useEffect, useState } from "react";

const useIsMobile = (): boolean => {
    const [isMobile, setIsMobile] = useState<boolean>(
        typeof window === "undefined" ? false : window.innerWidth <= 768,
    );

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        const handleResize = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                setIsMobile(window.innerWidth <= 768);
            }, 100);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return isMobile;
};

export default useIsMobile;
