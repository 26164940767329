import loadable from "@loadable/component";
import AnonymousRouteProvider from "./RouteProvider/AnonymousRouteProvider";
import BaseAuth from "../Landing/layout/BaseAuth";

const Register = loadable(
    () => import("../Components/Authentication/Register/Register"),
);
const Login = loadable(
    () => import("../Components/Authentication/Login/Login"),
);

const Error = loadable(() => import("../Layouts/Error/Error"));
const ForgotPassword = loadable(
    () =>
        import(
            "../Components/Authentication/Password/ForgotPassword/ForgotPassword"
        ),
);
const NewPassword = loadable(
    () =>
        import("../Components/Authentication/Password/NewPassword/NewPassword"),
);

const PaymentSuccess = loadable(
    () => import("../Layouts/Payment/PaymentSuccess"),
);

const PaymentError = loadable(() => import("../Layouts/Payment/PaymentError"));

const TemplateEmail = loadable(
    () => import("../Components/Extra/Email/TemplateEmail"),
);

const Maintenance = loadable(() => import("../Layouts/Maintenace/Maintenance"));

const PublicRoutes = [
    {
        path: "/payment-error",
        element: <PaymentError />,
    },
    {
        path: "/thank-you",
        element: <PaymentSuccess />,
    },
    {
        element: <AnonymousRouteProvider />,
        children: [
            {
                element: <BaseAuth />,
                children: [
                    {
                        path: "login",
                        element: <Login />,
                    },
                    {
                        path: "/register/:parrain?",
                        element: <Register />,
                    },
                    {
                        path: "/forgot-password",
                        element: <ForgotPassword />,
                    },
                    {
                        path: "/reset-password",
                        element: <NewPassword />,
                    },
                ],
            },
        ],
    },
    {
        path: "*",
        element: <Error />,
    },
    {
        path: "email",
        element: <TemplateEmail />,
    },
    {
        path: "maintenance",
        element: <Maintenance />,
    },
];

export default PublicRoutes;
