import React from "react";
import translator from "../../Components/Extra/Translation/Translate";
import Banner from "../component/banner";

const LegalNotice: React.FC = () => {
    const { translate } = translator();
    return (
        <>
            <Banner title={translate("Mention", "Title")} description="" />
            <div className="bg-white">
                <section className="flex justify-center items-center mx-8 lg:mx-32  py-16  text-black">
                    <div className="max-w-screen-xl mx-auto w-full">
                        <div className="max-w-screen-xl mx-auto w-full text-center  text-slate-888 ">
                            <h1 className="text-white font-bold text-5xl sm:text-3xl lg:text-6xl leading-tight tracking-tighter"></h1>
                            <div>
                                <div className="bg-[#e5e7eb] text-left md:px-[140px] px-[20px] py-10  rounded-tl-[120px]  ">
                                    <span>
                                        <h5 className="text-[14px]">
                                            1. {translate("Mention", "Title1")}
                                        </h5>
                                        <p className="text-[14px]">
                                            {translate("Mention", "Title1_p1a")}{" "}
                                            <a
                                                href="https://clishanodes.com"
                                                className="text-[#EEA913]"
                                            >
                                                https://clishanodes.com
                                            </a>{" "}
                                            {translate("Mention", "Title1_p1b")}
                                        </p>
                                    </span>
                                    <span>
                                        <h5 className="text-[14px] mt-[30px]">
                                            {translate("Mention", "Owner")} :
                                            CLISHANODES
                                        </h5>
                                    </span>
                                    <span className="flex items-center space-x-2">
                                        <h5 className="text-[14px]">
                                            Company No :
                                        </h5>
                                        <p>2948312</p>
                                    </span>

                                    <span className="flex items-center space-x-2 ">
                                        <h5 className="text-[14px]">
                                            {translate("Mention", "Contact")} :
                                        </h5>
                                        <p>contact@clishanodes.com</p>
                                    </span>

                                    <div>
                                        <h5 className="text-[14px]">
                                            {translate("Mention", "Adress")} :
                                        </h5>
                                        <div>
                                            <p>
                                                VQualis Limited Grand Industrial
                                                Building,
                                            </p>
                                            <p>
                                                Office 7/F 159-165 Wo Yi Hop
                                                Road Kwai Chung,
                                            </p>
                                            <p>Hongkong</p>
                                        </div>
                                    </div>

                                    <div className="mb-5">
                                        <div className="flex items-center mt-[30px]">
                                            <h5 className="text-[14px]">
                                                {translate(
                                                    "Mention",
                                                    "Hosting",
                                                )}{" "}
                                                :
                                            </h5>
                                            <p className="ml-1">
                                                <a
                                                    href="https://ionos.fr"
                                                    target="_blank"
                                                >
                                                    IONOS SARL
                                                </a>
                                            </p>
                                        </div>
                                        <div>
                                            <p>7, place de la Gare</p>
                                            <p>BP 70109</p>
                                            <p>57201 SARREGUEMINES</p>
                                        </div>
                                    </div>

                                    <span>
                                        <h5 className="text-[14px]">
                                            2. {translate("Mention", "Title2")}
                                        </h5>
                                        <p>
                                            {translate("Mention", "Title2_p")}
                                        </p>
                                    </span>

                                    <span>
                                        <h5 className="text-[14px] mt-[30px]">
                                            3. {translate("Mention", "Title3")}
                                        </h5>
                                        <p>
                                            {translate("Mention", "Title3_p")}
                                        </p>
                                    </span>
                                    <div className="flex flex-col mt-[30px]">
                                        <h5 className="text-[14px]">
                                            4. {translate("Mention", "Title4")}
                                        </h5>
                                        <span className="flex flex-col">
                                            <p>
                                                {translate(
                                                    "Mention",
                                                    "Title4_p1",
                                                )}
                                            </p>
                                            <p>
                                                {translate(
                                                    "Mention",
                                                    "Title4_p2",
                                                )}
                                            </p>
                                        </span>
                                    </div>
                                    <span>
                                        <h5 className="text-[14px] mt-[30px]">
                                            <span>
                                                <h5 className="text-[14px] mt-[30px]">
                                                    5.{" "}
                                                    {translate(
                                                        "Mention",
                                                        "Title5",
                                                    )}
                                                </h5>
                                                <p>
                                                    {translate(
                                                        "Mention",
                                                        "Title5_p",
                                                    )}
                                                </p>
                                            </span>
                                        </h5>
                                    </span>

                                    <span>
                                        <h5 className="text-[14px] mt-[30px]">
                                            6. {translate("Mention", "Title6")}
                                        </h5>
                                        <p>
                                            {translate("Mention", "Title6_p")}
                                        </p>
                                    </span>

                                    <span>
                                        <h5 className="text-[14px] mt-[30px]">
                                            7. {translate("Mention", "Title7")}
                                        </h5>
                                        <p>
                                            {translate("Mention", "Title7_p")}
                                            <span className="font-bold">
                                                {" "}
                                                CLISHANODES.
                                            </span>
                                        </p>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default LegalNotice;
