import React from "react";

import Section1 from "../ui/Affiliation/Section1";
import AffiliationSecondSection from "../ui/Affiliation/Section2";
import AffiliationThirdSection from "../ui/Affiliation/section3";
import AffiliationFourSection from "../ui/Affiliation/Section4";
import AffiliationFiveSection from "../ui/Affiliation/Section5";
import HelmetComponent from "../component/HelmetComponent";
import translator from "../../Components/Extra/Translation/Translate";

export default function Affiliation() {
    const { translate } = translator();
    return (
        <>
            <HelmetComponent
                title={translate("SEO", "Affiliation_title")}
                description={translate("SEO", "Affiliation_desc")}
                link="/affiliations"
                keywords={translate("SEO", "Affiliation_keywords")}
                imageCard="/dist/image/clishanodes-cards-1024x1024.png"
                largeTwitterCard="/dist/image/clishanodes-cards-1024x1024.png"
                addPostfixTitle={true}
                noIndex={false}
            ></HelmetComponent>
            <Section1 />
            <AffiliationSecondSection />
            <AffiliationThirdSection />
            <AffiliationFourSection />
            <AffiliationFiveSection />
        </>
    );
}
