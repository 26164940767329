interface Parrain {
    uid: string;
    first_name: string;
    last_name: string;
}
/* eslint-disable */
export enum UserType {
    PARTICULIER = "particulier",
    ENTERPRISE = "enterprise",
}
/* eslint-enable */
export default class User {
    id?: number;
    uid: string;
    reinvest_auto: boolean;
    hide_zero_amount: boolean;
    hide_not_active_earning?: boolean;
    user_name: string;
    last_name: string;
    first_name: string;
    email: string;
    scopes?: string[];
    photo?: string;
    parrain?: Parrain;
    haveTransactionPassword?: boolean;
    created_at?: Date;
    cap?: number;
    cag?: number;
    is_active?: boolean;
    is_fiat?: boolean;
    kyc?: string;
    type?: UserType;
    entreprise?: string;
    siret?: string;
    fidelity?: any;
    constructor(
        uid: string,
        user_name: string,
        last_name: string,
        first_name: string,
        email: string,
        reinvest_auto: boolean,
        hide_zero_amount: boolean,
    ) {
        this.uid = uid;
        this.user_name = user_name;
        this.last_name = last_name;
        this.first_name = first_name;
        this.email = email;
        this.reinvest_auto = reinvest_auto;
        this.hide_zero_amount = hide_zero_amount;
    }
}

export interface IUserSignUp {
    email: string;
    first_name: string;
    last_name: string;
    username?: string;
    password: string;
    uid_parrain?: string;
    token?: string | null;

    type?: string;
    entreprise?: string;
    siret?: string;

    lang: string;
}

export interface IUserPasswordUpdate {
    oldPassword: string;
    newPassword: string;
}
export interface IUserPasswordReset {
    new_password: string;
    confirm_password: string;
    token: string;
}

export interface IUserTransactionPasswordCreate {
    transactionPassword: string;
}

export interface User2Fa {
    key?: string;
    application?: string;
    username?: string;
    status: boolean;
}
export interface Token2Fa {
    token: string;
}
export interface IUserRequestResetPassword {
    email: string;
    url: string;
    lang: string;
}
export interface DirectActifCount {
    children_with_deleted: number;
    children: number;
    child_grandchild: number;
    nb_direct_actif: number;
    wallet_amount: number;
}

export interface PublicUserDta {
    email: string;
    first_name: string;
    last_name: string;
    username: string | null | undefined;
    photo: string | null | undefined;
    uid: string;
    cap?: number;
    cag?: number;
    is_active?: boolean;
    children: PublicUserDta[];
}

export interface ICommissionType {
    type: boolean;
}

export interface IEditProfil {
    uid: string;
    email: string;
}

export interface IResetTransactionPassword {
    code: string;
    new_password: string;
}

export interface IAddAffiliation {
    uid_to_add: string;
    uid_parrain: string;
}

export interface HeaderFilter {
    [key: string]: {
        filter: boolean;
        is_asc: boolean;
    };
}
