import { Outlet } from "react-router-dom";
import TabNav, { TabItem } from "../../../Components/Extra/Tab/TabNav";
import BreadCrumbV2, {
    Ariane,
} from "../../../Layouts/Account/BreadCrumb/BreadCrumbV2";

const navigation: TabItem[] = [
    {
        path: "users",
        name: "Admin_Pack_Booster.List_Of_All_User_With_Booster_Pack_Commands",
    },
    {
        path: "fidelities",
        name: "Admin_Pack_Booster.List_Of_All_Booster_Pack_Commands",
    },
];

const LoyaltyConfig = () => {
    const Arianes: Ariane[] = [
        {
            path: "#",
            name: "Admin",
        },
        {
            path: "/admin/loyalty-orders",
            name: "loyalty-orders",
        },
    ];

    return (
        <>
            <BreadCrumbV2 arianes={Arianes} />
            {/* <h2 className="intro-y mt-10 bg-white p-2 rounded text-lg font-medium">
                Asset Config
            </h2> */}
            <div className="mt-5 pt-5">
                <TabNav items={navigation} />
                <div className="intro-y col-span-12 box">
                    <div className="-mx-5 flex flex-col border-b border-slate-200/60 pb-5 lg:flex-row relative p-3">
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    );
};

export default LoyaltyConfig;
