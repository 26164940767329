import { IUserSignUp } from "../../Models/User";
import axios from "../AxiosInstance";

const verifyToken = async () => {
    try {
        const result = await axios.post(`/verify-token`);
        return {
            error: false,
            response: result,
        };
    } catch (err: any) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const getUser = async (token?: string, cookies?: string) => {
    const result = await getInformation(token, cookies);

    if (result.error && result.response) {
        //check 2FA
        if (result.response.data.code === "2FA_NEEDED") {
            return {
                user: undefined,
                fa2: true,
            };
        }

        return {
            user: undefined,
            fa2: false,
        };
    }

    const user = result.response.data;

    return {
        user: user,
        fa2: false,
    };
};

const signIn = async (
    username: string,
    password: string,
    recaptcha_token: string | null,
    rememberMe: boolean,
) => {
    try {
        const result = await axios.post(`/auth/sign-in`, {
            login: username,
            password,
            token: recaptcha_token,
            rememberMe: rememberMe,
        });

        return {
            error: false,
            response: result,
        };
    } catch (err: any) {
        return {
            error: true,
            response: err.response,
            message: err.message,
        };
    }
};

const singinVendo = async (token: string) => {
    try {
        const result = await axios.post(`/auth/vendo`, {
            token,
        });

        return {
            error: false,
            response: result,
        };
    } catch (err: any) {
        return {
            error: true,
            response: err.response,
            message: err.message,
        };
    }
};

const getVendoAuthUrl = async (): Promise<string> => {
    try {
        const result = await axios.get(`/auth/vendo/url`);
        return result.data.url;
    } catch (err: any) {
        throw new Error(`Une erreur est survenue ${err.message}`);
    }
};

const verify2FA = async (token: string) => {
    try {
        const result = await axios.post(`/auth/verify-2fa`, {
            token,
        });

        return {
            error: false,
            response: result,
        };
    } catch (err: any) {
        return {
            error: true,
            response: err.response,
            message: err.message,
        };
    }
};

const logout = async () => {
    try {
        return await axios.get(`/auth/logout`);
    } catch (err: any) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const getInformation = async (token?: string, cookies?: string) => {
    try {
        const result = await axios.get(`/user/information`, {
            headers: {
                ...(token && {
                    Authorization: token,
                }),
                ...(cookies && {
                    Cookie: cookies,
                }),
            },
        });
        return {
            error: false,
            response: result,
        };
    } catch (err: any) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const signUp = async (payload: IUserSignUp, repeatPassword: string) => {
    try {
        if (payload.password !== repeatPassword) {
            return Promise.reject(
                "Password and repeat password does not match",
            );
        }
        const result = await axios.post(`/auth/signup`, {
            ...payload,
        });
        return Promise.resolve(result.data.message);
    } catch (err: any) {
        const error =
            err.response.data && err.response.data.message
                ? err.response.data.message
                : err.message;
        return Promise.reject(error);
    }
};

export {
    verifyToken,
    signIn,
    logout,
    getInformation,
    signUp,
    verify2FA,
    getUser,
    singinVendo,
    getVendoAuthUrl,
};
