import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import useScrollTop from "../../hooks/useScrollTop";
import AosAnimation from "../../hooks/useAosAnimation";
import ScrollToTopButton from "../component/scrollButton/ScrollButton";

export default function Base() {
    useScrollTop();
    AosAnimation();

    return (
        <>
            <Header />
            <Outlet />
            <ScrollToTopButton />
            <Footer />
        </>
    );
}
