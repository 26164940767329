import React from "react";

import translator from "../../../Components/Extra/Translation/Translate";
import CustomButton from "../../component/Bouton/CustomButton";

export default function ExchangeFourthSection() {
    const { translate } = translator();

    return (
        <>
            <div className="bg-clisha-600 ">
                <section className="flex justify-center items-center mx-8 py-32  lg:mx-32">
                    <div className="max-w-screen-xl mx-auto w-full text-center">
                        <div data-aos="zoom-in">
                            <h2 className="text-white font-bold md:font-bold  md:text-3xl text-3xl pb-5">
                                {translate("EXCHANGE", "SECTION_3_TITLE")}
                            </h2>

                            <p className="text-white md:text-xl text-xl">
                                {translate("EXCHANGE", "SECTION_4_TITLE")}
                            </p>
                            <div className="flex flex-wrap md:gap-0 leading-normal gap-3 justify-center items-center space-x-2">
                                <CustomButton
                                    href="/register"
                                    className=" text-base  px-4 py-2 text-white !border-transparent rounded-xl bg-clisha-500 hover:bg-clisha-600  mt-8"
                                >
                                    {translate("GENERAL", "CREATE_ACCOUNT")}
                                </CustomButton>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}
