import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import "./App.css";
import { store } from "../globalState/Store";
import AuthenticationProvider from "./AuthenticationProvider";
import MessageProvider from "./MessageProvider";
import AppRoutes from "../Routes/AppRoutes";
import { createStore } from "@reduxjs/toolkit";
import reducers from "../globalState/reducers";
import { ChangeAppLanguage } from "../globalState/reducers/Language.reducer";

function App() {
    let storage = store;
    if (typeof window !== "undefined") {
        const initialState = (window as any).__INITIAL_STATE__;
        if (initialState) {
            storage = createStore(reducers, {
                auth: initialState.auth,
                language: store.getState().language,
                theme: store.getState().theme,
            });
            storage.dispatch(
                ChangeAppLanguage({
                    language: store.getState().language.language,
                }),
            );
        }
    }

    return (
        <Provider store={storage}>
            <AuthenticationProvider>
                <MessageProvider>
                    <BrowserRouter
                        basename={process.env.REACT_APP_BASE_NAME || "/"}
                    >
                        <AppRoutes />
                    </BrowserRouter>
                </MessageProvider>
            </AuthenticationProvider>
        </Provider>
    );
}

export default App;
