import React from "react";

export type NewCardProps = {
    image: string;
    title_1: string;
    text_1: string;
    title_2: string;
    text_2: [string, string];
};

export default function NewCard({
    image,
    title_1,
    text_1,
    title_2,
    text_2,
}: NewCardProps) {
    return (
        <>
            <div data-aos="zoom-out" className="mx-4">
                <div className="md:float-left md:w-1/2 md:mr-5">
                    <img src={image} className="w-full h-full object-cover" />
                </div>
                <div>
                    <div>
                        <h3 className="text-gray-800 text-xl font-semibold mb-3">
                            {title_1}
                        </h3>
                        <p className="mt-3 text-sm text-gray-500 text-justify leading-relaxed">
                            {text_1}
                        </p>
                    </div>
                    <div className="pt-6">
                        <h3 className="text-gray-800 text-xl font-semibold mb-3">
                            {title_2}
                        </h3>
                        {text_2.map((item, index) => (
                            <p
                                key={index}
                                className="mt-3 text-sm text-gray-500 text-justify leading-relaxed"
                            >
                                {item}
                            </p>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}
