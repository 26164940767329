import React from "react";
import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface FeatureCardProps {
    icon: IconProp;
    title: string;
    description: string;
    classname?: string;
}

const FeatureCard: FC<FeatureCardProps> = ({
    icon,
    title,
    description,
    classname,
}) => {
    return (
        <div
            className={`bg-white rounded-2xl overflow-hidden shadow-md hover:shadow-lg transition-all ${classname}`}
        >
            <div className="p-8 flex flex-col items-center">
                <FontAwesomeIcon
                    icon={icon}
                    className="text-clisha-500 text-4xl pb-4"
                />
                <h3 className="text-gray-800 text-xl font-semibold mb-3 text-center">
                    {title}
                </h3>
                <p className="text-gray-500 text-sm leading-relaxed text-center">
                    {description}
                </p>
            </div>
        </div>
    );
};

export default FeatureCard;
