import axios from "../AxiosInstance";
import UtilsService from "../UtilsService";

export interface EarnPlan {
    planId: number;
    active: boolean;
    days: number;
    roi: number;
    name: string;
}

export interface EarnCryptoPlans {
    symbol: string;
    name: string;
    logo: string;
    usdPrice: number;
    last24Hrs: number;
    plans: EarnPlan[];
    defaultRoi: number;
    defaultPlanId: number;
}

export async function getEarnPlans(): Promise<EarnCryptoPlans[]> {
    return axios
        .get(`/earn/plans`)
        .then(({ data }) => {
            return data.map((plan: EarnCryptoPlans) => {
                let defaultRoi = 0;
                let defaultPlanId = 0;

                const firstPlan = plan.plans[0];
                if (firstPlan) {
                    defaultRoi = firstPlan.roi;
                    defaultPlanId = firstPlan.planId;
                }

                return { ...plan, defaultRoi, defaultPlanId };
            });
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
}
