import React, { useState } from "react";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    questionsData1,
    questionsData2,
    questionsData3,
    questionsData4,
} from "./QuestionData";
import translator from "../../../Components/Extra/Translation/Translate";

interface Question {
    question: string;
    answer: string;
}

const Faq: React.FC = () => {
    const [openIndexes, setOpenIndexes] = useState<{ [key: string]: number[] }>(
        {
            section1: [],
            section2: [],
            section3: [],
            section4: [],
        },
    );
    const question1 = questionsData1();
    const question2 = questionsData2();
    const question3 = questionsData3();
    const question4 = questionsData4();

    const toggleDropdown = (section: string, index: number) => {
        setOpenIndexes((prevIndexes) => {
            if (prevIndexes[section].includes(index)) {
                return {
                    ...prevIndexes,
                    [section]: prevIndexes[section].filter((i) => i !== index),
                };
            } else {
                return {
                    ...prevIndexes,
                    [section]: [...prevIndexes[section], index],
                };
            }
        });
    };

    const renderQuestions = (questions: Question[], section: string) => {
        return questions.map((question, index) => (
            <div key={index} className="mb-4">
                <h5 className="font-bold leading-10 font-montserrat text-[#212529] pb-1">
                    <button
                        onClick={() => toggleDropdown(section, index)}
                        className="flex justify-between items-center w-full"
                    >
                        {question.question}
                        <FontAwesomeIcon
                            className={`transition-transform duration-700 ${
                                openIndexes[section].includes(index)
                                    ? "rotate-180"
                                    : ""
                            }`}
                            icon={
                                openIndexes[section].includes(index)
                                    ? faMinus
                                    : faPlus
                            }
                        />
                    </button>
                </h5>
                <div
                    className={`overflow-hidden transform transition-all duration-700 ${
                        openIndexes[section].includes(index)
                            ? "max-h-96 scale-100 opacity-100"
                            : "max-h-0 scale-0 opacity-0"
                    }`}
                >
                    <p
                        className="text-[14px] text-[#97A9A8] mt-2 pl-5"
                        dangerouslySetInnerHTML={{
                            __html: question.answer
                                .replace(/- /g, "<br>- ")
                                .replace(/\.\s/g, ".<br>"),
                        }}
                    />
                </div>
            </div>
        ));
    };

    const { translate } = translator();
    return (
        <div className="bg-[#353D42]">
            <section className="flex justify-center items-center py-14 mx-8 md:mx-8 lg:mx-32">
                <div className="max-w-screen-xl mx-auto w-full">
                    <div className="text-center my-6 pb-10 space-y-3">
                        <h1 className="text-clisha-500 bg-clip-text font-semibold text-5xl sm:text-3xl lg:text-6xl leading-tight tracking-tighter">
                            {translate("FAQ", "SECTION_2_TITLE_1")}
                        </h1>
                    </div>
                    <div className="flex flex-col justify-center items-center md:justify-start md:items-start space-y-12">
                        <div
                            data-aos="fade-down"
                            className="relative flex justify-center items-center w-full"
                        >
                            <div
                                className="bg-[#ffffff] border rounded-xl p-6 md:pt-10 w-full"
                                data-aos="flip-left"
                            >
                                {renderQuestions(question1, "section1")}
                            </div>
                        </div>
                        <div className="!text-white space-y-2">
                            <h1 className="text-clisha-500 bg-clip-text font-semibold text-5xl sm:text-3xl text-center lg:text-6xl leading-tight tracking-tighter">
                                {translate("FAQ", "SECTION_2_TITLE_2")}
                            </h1>
                        </div>
                        <div
                            data-aos="fade-down"
                            className="relative flex justify-center items-center w-full"
                        >
                            <div
                                className="bg-[#ffffff] border rounded-xl p-6 md:pt-10 w-full"
                                data-aos="flip-left"
                            >
                                {renderQuestions(question2, "section2")}
                            </div>
                        </div>
                        <div className="!text-white space-y-2">
                            <h1 className="text-clisha-500 bg-clip-text font-semibold text-5xl sm:text-3xl text-center lg:text-6xl leading-tight tracking-tighter">
                                {translate("FAQ", "SECTION_2_TITLE_3")}
                            </h1>
                        </div>
                        <div
                            data-aos="fade-down"
                            className="relative flex justify-center items-center w-full"
                        >
                            <div
                                className="bg-[#ffffff] border rounded-xl p-6 md:pt-10 w-full"
                                data-aos="flip-left"
                            >
                                {renderQuestions(question3, "section3")}
                            </div>
                        </div>
                        <div className="!text-white space-y-2">
                            <h1 className="text-clisha-500 bg-clip-text font-semibold text-5xl sm:text-3xl text-center lg:text-6xl leading-tight tracking-tighter">
                                {translate("FAQ", "SECTION_2_TITLE_4")}
                            </h1>
                        </div>
                        <div
                            data-aos="fade-down"
                            className="relative flex justify-center items-center w-full"
                        >
                            <div
                                className="bg-[#ffffff] border rounded-xl p-6 md:pt-10 w-full"
                                data-aos="flip-left"
                            >
                                {renderQuestions(question4, "section4")}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Faq;
