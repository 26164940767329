import React from "react";

import translator from "../../../Components/Extra/Translation/Translate";
import CustomButton from "../../component/Bouton/CustomButton";

export type DiscoverNewsProps = {
    styleBtn: string;
};

export default function DiscoverNews({ styleBtn }: DiscoverNewsProps) {
    const { translate } = translator();
    return (
        <CustomButton href="" className={styleBtn}>
            {translate("GENERAL", "DISCOVER_NEWS")}
        </CustomButton>
    );
}
