import React from "react";
import translator from "../../../Components/Extra/Translation/Translate";
import CreateAccountButton from "../buttons/create-account";

export default function BuyFourthSection() {
    const { translate } = translator();

    return (
        <>
            <div className="bg-clisha-600 py-32 ">
                <section className="flex justify-center items-center mx-8 lg:!mx-32">
                    <div
                        className="max-w-screen-xl mx-auto w-full text-center"
                        data-aos="zoom-in"
                    >
                        <div className="">
                            <h2 className="text-white font-bold md:font-bold  md:!text-4xl text-2xl pb-5">
                                {translate("BUY", "SECTION_4_TITLE")}
                            </h2>

                            <p className="text-white md:!text-xl text-lg">
                                {translate("BUY", "SECTION_4_DESCRIPTION")}
                            </p>
                            <div className="flex flex-wrap md:gap-0 leading-normal gap-3 justify-center items-center space-x-2">
                                <CreateAccountButton yellow />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}
